import React, { useEffect } from "react";
import { Stack } from "@mui/material";

import { useAxios } from "../../../util/useAxios";

const Results = () => {
    const axios = useAxios();
    useEffect(() => {
        const report = axios.get('generate-report');
        console.log('report', report);
    },[]);
    return (
        <Stack  spacing={2} mt={5} direction="column">
            <p>Thank you for completing the survey!</p>
            <p>Your results have been emailed to you.</p>
        </Stack>
    )
}
export default Results;