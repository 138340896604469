import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent, useState } from "react";
import Liability from "./Liability";
import {ILiability, Repayment, SurveyProps, PERIOD} from "@interfaces";

export default function Liabilities({gotoNextStep, isLoading, submitError, isSubmitError}:SurveyProps) {

    const [liabilities, setLiabilities] = useState<{hasLiabilities: boolean, liabilities: ILiability[]}>({
        hasLiabilities: false,
        liabilities: [{ 
            type: '',
            borrower: '',
            customType: '',
            balance: '',
            interestRate: '',
            repaymentType: '',
            repayment: {
                amount: 0,
                period: PERIOD.Yearly
            }
        }]
    });

    const handleSetLiabilities = (value: boolean) => {
        if(value !== null) {
            setLiabilities({...liabilities, hasLiabilities: value});
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const liabilitiesWithoutCustom:Array<ILiability> = liabilities.liabilities.map(liability =>  {
            const newLiability = {...liability};
            newLiability.type = (liability.customType && liability.customType !== '') ? liability.customType : liability.type;
            delete newLiability.customType;
            return newLiability;
        });
        gotoNextStep(liabilities.hasLiabilities ? liabilitiesWithoutCustom : []);
    };

    const addLiability = () => {
        handleUpdateLiability('type', '', liabilities.liabilities.length);
    }
    
    const handleUpdateLiability = (field: string, data:string|number, index: number ) => {
        const newLiabilities = [...liabilities.liabilities];
        const keyPath = field.split('.');

        if(!newLiabilities[index]) {
            const newLiability:{[k: string]: any} = {};
            const blankLiability = { 
                type: '',
                borrower: '',
                customType: '',
                balance: '',
                interestRate: '',
                repaymentType: '',
                repayment: {
                    amount: 0,
                    period: PERIOD.Yearly
                }
            };
            if(keyPath.length === 2){
                newLiability[keyPath[0]][keyPath[1]] = data;
            } else {
                newLiability[keyPath[0]] = data;
            }
            
            newLiabilities.push(blankLiability);
        } else {
            if(keyPath.length === 1) {
                newLiabilities[index][keyPath[0] as keyof Omit<ILiability, 'repayment'>] = data as string;
            } else if(keyPath.length === 2) {
                newLiabilities[index][keyPath[0] as keyof Pick<ILiability, 'repayment'>][keyPath[1] as keyof Repayment] = data as number;
            }
        }
        setLiabilities({...liabilities, liabilities: newLiabilities});
    }

    const deleteLiability = (index:number) => {
        const newLiabilities = [...liabilities.liabilities];
        newLiabilities.splice(index, 1);
        setLiabilities({...{hasLiabilities: !!newLiabilities.length, liabilities: newLiabilities}});
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <Stack spacing={5} direction="column"> 
                <div className="survey-subheading">Do you have any liabilities?</div>
                <ToggleButtonGroup
                    color="primary"
                    value={liabilities.hasLiabilities}
                    exclusive
                    onChange={(event, value) => handleSetLiabilities(value)} 
                    >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                {liabilities.hasLiabilities && 
                <>
                    {liabilities.liabilities.map((liability, index) => 
                        <Liability liability={liability} updateLiability={(field: string, data: string) => handleUpdateLiability(field, data, index)} addLiability={addLiability} key={index} deleteLiability={() => deleteLiability(index)} forceDisplayAddButton={liabilities.liabilities.length === 1}/>
                    )}
                </>

                }
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }} >Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
        </>
    )
}
