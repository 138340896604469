import { UserParams, VerificationParams } from "./interfaces/user";


import {
    CognitoUserPool,
    CognitoUserAttribute,
    AuthenticationDetails,
    CognitoUser,
} from "amazon-cognito-identity-js";

import appConfig from "./cognitoConfig";

const userPool = new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
});


const authProvider = {
    isAuthenticated: false,
    verifyEmail(data: VerificationParams,  successCb: (message: string) => void, failedCb: (message: Error) => void) {
        const userData = {
            Username: data.username,
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(data.verificationCode, true, (err) => {
            if (err) {
                failedCb(err);
                return;
            }
            authProvider.isAuthenticated = true;
            const verifyEmailUserData:UserParams = {
                username: data.username,
                password: data.password,
            }
            this.signin(verifyEmailUserData, 
                () => { 
                    successCb("SUCCESS");
                },
                () => {
                    failedCb(err);
                }
            );
        });
    },
    register(data: UserParams, successCb: (user: CognitoUser) => void, failedCb: (message: Error) => void) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: data.username.toLowerCase().trim()
            })
        ];
        userPool.signUp(data.username.toLowerCase().trim(), data.password.trim(), attributeList, [], (err, result) => {
            if (err || !result) {

                if (err) failedCb(err);
                return;
            }
            const userData = {
                Username: data.username.toLowerCase().trim(),
                Pool: userPool
            };
            
            successCb(new CognitoUser(userData));
        })
    },
    async signin(data: UserParams, successCb: (user: CognitoUser) => void, failedCb: (message: Error) => void) {
        const authDetails = new AuthenticationDetails({
            Username: data.username,
            Password: data.password
        });

        const userData = {
            Username: data.username,
            Pool: userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: result => {
                authProvider.isAuthenticated = true;
                successCb(cognitoUser);
            },
            onFailure: result => {
                authProvider.isAuthenticated = false;
                failedCb(result);
            }
        })
    },
    async signout(user: CognitoUser, callback: VoidFunction) {
        user.signOut();
        authProvider.isAuthenticated = false;
        callback();
    },
    

}

export { authProvider };
