import { InvestmentProps } from "@interfaces";
import { Stack, Button, TextField, MenuItem } from "@mui/material";
import React, { ChangeEvent } from "react";

export function Investments({updateInvestment, addInvestment, deleteInvestment, displayDelete, displayAdd, investment}: InvestmentProps) {
    const ownerOptions = ["Client", "Partner", "Joint"];
    const investmentTypeOptions = [
        {
            value: "shares",
            label: "Shares"
        },
        {
            value: "property",
            label: "Property"
        },
        {
            value: "bonds",
            label: "Fixed Interest/Bonds"
        },
        {
            value: "other",
            label: "Other"
        }
    ]
    return <Stack spacing={1}>
        <hr/>
        {displayDelete && <Button type="button" variant="outlined" onClick={e => deleteInvestment()}>Delete</Button>}
        <TextField required variant="outlined" label="Name" onChange={(event: ChangeEvent<HTMLInputElement>) => updateInvestment({name: event.target.value})} value={investment?.name ?? ''} name="name" fullWidth/>
        <TextField select required value={investment.type} sx={{minWidth: "Type".length * 20}} label="Type" name="type" onChange={(event) => updateInvestment({type:event.target.value})} variant="outlined">
            {investmentTypeOptions.map((option, index) => 
                <MenuItem value={option.value} key={index}>{option.label}</MenuItem>
            )}
        </TextField>
        {
            investment.type === 'shares' && <div>explain shares here</div>
        }
        {
            investment.type === 'property' && <div>explain property here</div>
        }
        {
            investment.type === 'bonds' && <div>explain bonds here</div>
        }
        {
            investment.type === 'other' && <div>explain other here</div>
        }
        <TextField select required value={investment.owner} sx={{minWidth: "Owner".length * 20}} label="Owner" name="type" onChange={(event) => updateInvestment({owner:event.target.value})} variant="outlined">
            {ownerOptions.map((option, index) => 
                <MenuItem value={option.toLowerCase()} key={index}>{option}</MenuItem>
            )}
        </TextField>

        <TextField required variant="outlined" label="value" onChange={(event: ChangeEvent<HTMLInputElement>) => updateInvestment({value: Number(event.target.value)})} value={investment?.value ?? ''} name="value" fullWidth/>

        {displayAdd && 
            <Button type="button" variant="outlined" onClick={addInvestment} >Add another account</Button> 
        }
    </Stack>
}