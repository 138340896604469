import { TextField, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState, useContext, useCallback } from "react";
import { AuthContext } from "../contexts";
import validator from 'validator';
import "./Register.scss";
import { useNavigate } from "react-router-dom";

type propTypes = {
    isActive: boolean,
    toggleActive: VoidFunction,
}

export default function Register({isActive, toggleActive}:propTypes) {
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userError, setUserError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [signUpStatus, setSignUpStatus] = useState('');
    const [awaitingVerifyCode, setAwaitingVerifyCode] = useState(false);
    const [emailValidationCode, setEmailValidationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = useContext(AuthContext);

    const navigate = useNavigate();

    const submitEmailValidationCode = useCallback(() => {
        if (emailValidationCode === '') { 
            setCodeError('Please enter the validation code.');
            return;
        }
        setLoading(true);
        const data = {
            username,
            password,
            verificationCode: emailValidationCode
        }
        auth.verifyEmail(data,
        (message) => {
            if (message === "SUCCESS") { navigate("/survey") }
        },
        (err) => {
            setSignUpStatus(err.message);        
            setLoading(false);
        });

    }, [setSignUpStatus, username, emailValidationCode, auth, navigate, password]);

    const submitRegister = useCallback(() => {
        if (username === '') { 
            setUserError("Please enter your email address.");
            return;
        }
        if (password === '') { 
            setPasswordError("Please enter a password.");
            return;
        }

        setLoading(true);
        auth.register({username, password}, () => {
            setAwaitingVerifyCode(true);
            setLoading(false);
        }, (err) => {          
            err.message !== "User is not confirmed."
                ? setSignUpStatus(err.message)
                : setAwaitingVerifyCode(true);
            setLoading(false);
        });
    }, [setAwaitingVerifyCode, setSignUpStatus, password, username, auth]);

    const handleKeyPressed = useCallback((e: React.KeyboardEvent) => {
        if(e.code === "Enter") {
            !awaitingVerifyCode 
                ? submitRegister()
                : submitEmailValidationCode()
        }
    },[awaitingVerifyCode, submitEmailValidationCode, submitRegister]);

    const handleEmailChange = (email: string) => {
        setUsername(email);
        setSignUpStatus('');
        if (validator.isEmail(email)) {
            setUserError('');
        } else {
            setUserError('Invalid email format');
        }
    }

    const handlePasswordChange = (passwordInput: string) => {
        setPassword(passwordInput);
        setSignUpStatus('');
        if(passwordInput.length < 6) {
            setPasswordError('Password needs to be 6 or more characters');
        } else {
            setPasswordError('');
        }
    }

    const handleCodeChange = (codeInput: string) => {
        setEmailValidationCode(codeInput);
        setSignUpStatus('');
        if(codeInput.length < 6) {
            setCodeError('Please enter a valid code');
        } else {
            setCodeError('');
        }
    }

    const userNameChange = useCallback((e) => handleEmailChange(e.target.value),[]);
    const userPassChange = useCallback((e) => handlePasswordChange(e.target.value),[]);
    const emailCodeChange = useCallback((e) => handleCodeChange(e.target.value),[]);
    const keyPressed = useCallback((e) => handleKeyPressed(e),[handleKeyPressed]);
    return (
        <div className={`register-container ${!isActive ? 'inactive' : ''}`}>
            <div className="register-body" onKeyUp={keyPressed} role="form" aria-hidden>
                <div className={`register-form ${isActive? 'display' : ''}`} >
                    <div className="heading">
                        Create Account
                    </div>
                    {
                        !awaitingVerifyCode 
                        ? 
                        <>                 
                            <TextField error={userError !== ''} fullWidth required variant="outlined" onChange={userNameChange} value={username} label="Email" helperText={userError} />
                            <TextField error={passwordError !== ''} required fullWidth type="password" label="Password" variant="outlined" onChange={userPassChange} value={password} helperText={passwordError} />
                            <LoadingButton 
                                loading={loading} 
                                size="large" 
                                color="cta" 
                                variant="contained" 
                                onClick={submitRegister}  
                                sx={{height: "40px"}}>
                                SIGN UP
                            </LoadingButton>
                            {(signUpStatus !== '') && <div className="error">{signUpStatus}</div>}
                        </> 
                        :   
                        <>
                            <div className="message">
                                Please check your email and enter the code below:
                            </div>
                            <TextField error={codeError !== ''} fullWidth required variant="outlined" onChange={emailCodeChange} value={emailValidationCode} label="Code" helperText={codeError} />
                            <LoadingButton 
                                loading={loading} 
                                size="large" 
                                color="cta" 
                                variant="contained" 
                                onClick={submitEmailValidationCode}  
                                sx={{height: "40px"}}>
                                VERIFY
                            </LoadingButton>
                            {(signUpStatus !== '') && <div className="error">{signUpStatus}</div>}
                        </>
                    }
                </div>
                <div className={`welcome-container ${!isActive ? 'display' : ''}`}>
                    <div className="heading">
                        First time here?
                    </div>
                    <div className="message">
                        Create an account to get started
                    </div>
                    <Button variant="outlined" size="large" color="cta" onClick={toggleActive} sx={{height: "40px"}}>  
                        SIGN UP
                    </Button>
                </div>
            </div>
        </div>
    )   
}