import { SavingsProps } from "@interfaces";
import { Stack, Button, TextField, MenuItem } from "@mui/material";
import React, { ChangeEvent } from "react";


export function Savings({updateSavings, addSavings, deleteSavings, displayDelete, displayAdd, savings}: SavingsProps) {
    const ownerOptions = ["Client", "Partner", "Joint"];
    return <Stack spacing={1}>
        <hr/>
        {displayDelete && <Button type="button" variant="outlined" onClick={e => deleteSavings()}>Delete</Button>}
        <TextField select required value={savings.owner} label="Owner" name="type" onChange={(event) => updateSavings({owner:event.target.value})} variant="outlined" fullWidth>
            {ownerOptions.map((option, index) => 
                <MenuItem value={option.toLowerCase()} key={index}>{option}</MenuItem>
            )}
        </TextField>
        <TextField required variant="outlined" label="Account balance" onChange={(event: ChangeEvent<HTMLInputElement>) => updateSavings({balance: Number(event.target.value)})} value={savings?.balance ?? ''} name="balance" fullWidth/>

        <TextField select required value={savings.mortgage} label="Is this a mortgage offset account?" name="type" onChange={(event) => updateSavings({mortgage: event.target.value === "true"})} variant="outlined" fullWidth>
                <MenuItem value="false">No</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
            </TextField>
        {displayAdd && <Button type="button" variant="outlined" onClick={addSavings} >Add another account</Button>}

    </Stack>
}