import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { SurveyProps, IInsurance, InsuranceValue } from "@interfaces";
import { Insurance } from './Insurance';

export default function Insurances({ gotoNextStep, isLoading, submitError, isSubmitError, user }: SurveyProps) {

    const [insurance, setInsurance] = useState({
        hasInsurance: false,
        insurances: [] as Array<IInsurance>,
    });

    const handleUpdateInsuranceData = useCallback((insuranceData: InsuranceValue, index: number) => {
        const newInsurances = [...insurance.insurances];
        if (Object.keys(insuranceData)[0] === 'type') {
            newInsurances[index] = { beneficiary: '', amount: 0, waitingPeriod: 0, benefitPeriod: '', ...insuranceData as { type: string } };
        }
        newInsurances[index] = { ...newInsurances[index], ...insuranceData };

        setInsurance({ ...insurance, insurances: newInsurances })
    }, [insurance]);

    const handleSetInsurance = (value: boolean) => {
        if (value !== null) {
            setInsurance({ ...insurance, hasInsurance: value });
        }
    }

    useEffect(() => {
        if (insurance.hasInsurance && insurance.insurances.length === 0) {
            handleUpdateInsuranceData({ type: '' }, 0);
        }
    }, [insurance, handleUpdateInsuranceData])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        gotoNextStep(insurance.insurances.length ? insurance.insurances : []);
    };

    const deleteInsurance = (index: number) => {
        const newInsurances = [...insurance.insurances];
        newInsurances.splice(index, 1);
        setInsurance({ ...insurance, insurances: newInsurances });
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={5} mt={1} direction="column">
                <div className="survey-subheading">
                    Do you have any life insurance in place?
                </div>
                <ToggleButtonGroup
                    color="primary"
                    value={insurance.hasInsurance}
                    exclusive
                    onChange={(event, value) => handleSetInsurance(value)}
                >
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                {insurance.hasInsurance &&
                    <>
                        {insurance.insurances.map((currentInsurance, index) =>
                            <Insurance insurance={currentInsurance} updateInsurance={insuranceData => handleUpdateInsuranceData(insuranceData, index)} addInsurance={() => handleUpdateInsuranceData({ type: "" }, insurance.insurances.length)} key={index} deleteInsurance={() => deleteInsurance(index)} displayDelete={insurance.insurances.length !== 1} displayAdd={index === insurance.insurances.length - 1} />
                        )}
                    </>

                }
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading}>Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
    )
}

