import {useContext} from 'react';
import { CognitoUser } from "amazon-cognito-identity-js";
import { AuthContext } from 'contexts';
import axios from 'axios';

const url = {
    root: 'https://64b84wk2fj.execute-api.ap-southeast-2.amazonaws.com',
    branch: 'master'
}

export const useAxios = () => {
    const auth = useContext(AuthContext);
    return axios.create({
        baseURL:`${url.root}/${url.branch}/`,
        headers: {
            'Authorization': (auth.user as CognitoUser).getSignInUserSession()?.getIdToken().getJwtToken()
        },
    })
}