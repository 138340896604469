import FinancialSatisfaction from "./steps/FinancialSatisfaction";
import UserDetails from "./steps/User";
import EmploymentSteps from "./steps/Employment";
import ChildrenComponent from "./steps/Children";
import IncomeExpenses from "./steps/IncomeExpenses";
import Liabilities from "./steps/Liabilities";
import Assets from "./steps/Assets";
import Insurance from "./steps/Insurance";
import Estate from "./steps/Estate";
import Knowledge from "./steps/Knowledge";
import Results from "./steps/Results";

export const stepsData = [
    {
        route: 'user', 

        title: 'A few more details',
        component: UserDetails
    },
    {
        route: 'financial-satisfaction', 
        
        title: 'Financial satisfaction survey',
        component: FinancialSatisfaction
    },
    {
        route: 'employment', 

        title: 'Employment and occupation',
        component: EmploymentSteps
    },
    {
        route: 'children', 

        title: 'Children',
        component: ChildrenComponent
    },
    {
        route: 'liabilities', 

        title: 'Liabilities',
        component: Liabilities
    },
    {
        route: 'income-expenses', 

        title: 'Income and expenses',
        component: IncomeExpenses
    },
    {
        route: 'assets', 

        title: 'Assets',
        component: Assets
    },
    {
        route: 'insurance', 

        title: 'Insurance',
        component: Insurance
    },
    {
        route: 'estate', 

        title: 'Estate planning',
        component: Estate
    },
    {
        route: 'knowledge', 

        title: 'Knowledge and experience on investments',
        component: Knowledge
    },
    {
        route: 'results', 

        title: 'Results',
        component: Results
    },
];