import { ChangeEventHandler } from "react";
import { Address, Partner, UserValues } from "@interfaces"
import { countries, states } from "../../../util/geoLists";

function isPartnerType(user: UserValues | Partner): user is UserValues {
    return (user as UserValues).maritalStatus !== undefined;
}

export const getFields = ({ handleTextInputChange, handleSetAddress, user, address }: {
    handleTextInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    handleSetAddress: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    user: UserValues | Partner,
    address: Address
}) => [
        {
            label: "First Name",
            onChange: handleTextInputChange,
            value: user.firstName,
            name: "firstName",
            required: true
        },
        {
            label: "Last Name",
            onChange: handleTextInputChange,
            value: user.lastName,
            name: "lastName",
            required: true
        },
        {
            label: "Street Number",
            onChange: handleSetAddress,
            value: address.streetNumber,
            name: "streetNumber",
            required: true
        },
        {
            label: "Street Name",
            onChange: handleSetAddress,
            value: address.streetName,
            name: "streetName",
            required: true
        },
        {
            label: "Suburb",
            onChange: handleSetAddress,
            value: address.suburb,
            name: "suburb",
            required: true
        },
        {
            label: "Postcode",
            onChange: handleSetAddress,
            value: address.postcode,
            name: "postcode"
        },
        {
            label: "State",
            onChange: handleSetAddress,
            value: address.state,
            name: "state",
            children: states.map(state => ({
                value: state.toLowerCase().replace(' ', '-'),
                key: state,
                content: state.split(' ').reduce((arr, stateWord) => arr + ' ' + stateWord[0].toUpperCase() + stateWord.slice(1).toLowerCase(), '')
            }))
        },
        {
            label: "Country",
            onChange: handleSetAddress,
            value: address.country,
            name: "country",
            children: countries.map(country => ({
                value: country.code3,
                key: country.code3,
                content: country.name
            }))
        },
        {
            label: "Email",
            onChange: handleTextInputChange,
            value: user.email,
            name: "email",
        },
        {
            label: "Gender",
            onChange: handleTextInputChange,
            value: user.gender,
            name: "gender",
            children: ["male", "female", "other"].map(gender => ({
                value: gender,
                key: gender,
                content: gender.charAt(0).toUpperCase() + gender.slice(1)
            }))
        },
        {
            useAlternateComponent: true
        },
        {
            label: "Marital Status",
            onChange: handleTextInputChange,
            value: isPartnerType(user) ? user.maritalStatus : '',
            name: "maritalStatus",
            children: [{
                value: "single",
                label: "Single"
            },
            {
                value: "married",
                label: "Married"
            },
            {
                value: "de-facto",
                label: "De facto or in an interdependent relationship"
            },
            {
                value: "other",
                label: "Other"
            }
            ].map(maritalStatus => ({
                value: maritalStatus.value,
                key: maritalStatus.value,
                content: maritalStatus.label
            }))
        },

    ]