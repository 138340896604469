import { Grid } from "@mui/material";
import React, { FormEvent, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { SurveyProps, CashFlow, DebtManagement, EstateSatisfaction, InvestmentManagement, Retirement, RiskManagement } from "@interfaces";
import FinancialSatisfactionItem from "./FinancialSatisfactionItem";
import "./FinancialSatisfactionSurvey.scss";

export default function FinancialSatisfaction({gotoNextStep, isLoading, submitError, isSubmitError, user}:SurveyProps) {
    const [displayNext, setDisplayNext] = useState(false);

    const [cashFlow, setCashFlow] = useState<CashFlow>({
        savedAmount: {
            client: 0,
        },
        taxPaid: {
            client: 0
        },
        expenseManagement: {
            client: 0
        },
        savingsUsed: {
            client: 0
        },
    });

    const [investmentManagement, setInvestmentManagement] = useState<InvestmentManagement>({
        understandInvestmentProducts: {
            client: 0
        },
        canAnalyseInvestments: {
            client: 0
        },
        portfolioPerformance: {
            client: 0
        },
        riskLevel: {
            client: 0
        },
    });

    const [debtManagement, setDebtManagement] = useState<DebtManagement>({
        debt: {
            client: 0
        },
        loanPaymentTime: {
            client: 0
        },
        interestRateRiseManagement: {
            client: 0
        },
        canPayDebts: {
            client: 0
        },
    });

    const [riskManagement, setRiskManagement] = useState<RiskManagement>({
        understanding: {
            client: 0
        },
        contingencies: {
            client: 0
        },
        protection: {
            client: 0
        },
        insurance: {
            client: 0
        },
    });

    const [retirement, setRetirement] = useState<Retirement>({
        savings: {
            client: 0
        },
        understanding: {
            client: 0
        },
        longevity: {
            client: 0
        },
        super: {
            client: 0
        },
    });

    const [estate, setEstate] = useState<EstateSatisfaction>({
        lifeNomination: {
            client: 0
        },
        inheritance: {
            client: 0
        },
        deathNomination: {
            client: 0
        },
        taxMinimised: {
            client: 0
        },
    });

    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let hasError = false;

        [cashFlow, investmentManagement, debtManagement, riskManagement, retirement, estate].forEach((section) => {
            Object.values(section).forEach((key) => {
                if (key.client === 0) {
                    hasError = true;
                    setErrorMessage(`Please answer all questions`);
                }
            }
        )});

        if(hasError) return;

        setErrorMessage('');
        setDisplayNext(true);
    };

    // TODO: Reduce copy/paste and move questions into separate file

    const handleCashFlowChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof typeof cashFlow;
        let newObj;

        if (isPartner) {
            newObj = { ...cashFlow[fieldName], partner: value };
        } else {
            newObj = { ...cashFlow[fieldName], client: value };
        }
        setCashFlow({ ...cashFlow, [fieldName]: newObj });
    };

    const handleInvestmentProductsChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof typeof investmentManagement;
        let newObj;

        if (isPartner) {
            newObj = { ...investmentManagement[fieldName], partner: value };
        } else {
            newObj = { ...investmentManagement[fieldName], client: value };
        }
        setInvestmentManagement({ ...investmentManagement, [fieldName]: newObj });
    };

    const handleDebtManagmentChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof typeof debtManagement;
        let newObj;

        if (isPartner) {
            newObj = { ...debtManagement[fieldName], partner: value };
        } else {
            newObj = { ...debtManagement[fieldName], client: value };
        }
        setDebtManagement({ ...debtManagement, [fieldName]: newObj });
    }

    const handleRiskManagementChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof typeof riskManagement;
        let newObj;

        if (isPartner) {
            newObj = { ...riskManagement[fieldName], partner: value };
        } else {
            newObj = { ...riskManagement[fieldName], client: value };
        }
        setRiskManagement({ ...riskManagement, [fieldName]: newObj });
    }

    const handleRetirementChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof Retirement;
        let newObj;

        if (isPartner) {
            newObj = { ...retirement[fieldName], partner: value };
        } else {
            newObj = { ...retirement[fieldName], client: value };
        }
        setRetirement({ ...retirement, [fieldName]: newObj });
    }

    const handleEstateChange = (event: React.SyntheticEvent<Element, Event>, value: number, isPartner: boolean) => {
        const fieldName = event.currentTarget.getAttribute('name') as keyof EstateSatisfaction;
        let newObj;

        if (isPartner) {
            newObj = { ...estate[fieldName], partner: value };
        } else {
            newObj = { ...estate[fieldName], client: value };
        }
        setEstate({ ...estate, [fieldName]: newObj });
    }

    const fsCategories = [
        {
            title: "Cash flow management",
            questions: [
                {
                    text: "I am satisfied with the amount I save on a regular basis.",
                    fieldName: "savedAmount",
                    value: cashFlow.savedAmount,
                    changeFunction: handleCashFlowChange,
                },
                {
                    text: "I am satisfied that I have minimised the amount of tax I pay.",
                    fieldName: "taxPaid",
                    value: cashFlow.taxPaid,
                    changeFunction: handleCashFlowChange
                },
                {
                    text: "I am satisfied with the way I manage my expenses.",
                    fieldName: "expenseManagement",
                    value: cashFlow.expenseManagement,
                    changeFunction: handleCashFlowChange
                },
                {
                    text: "I am satisfied my savings are put to good use which will enhance my wealth.",
                    fieldName: "savingsUsed",
                    value: cashFlow.savingsUsed,
                    changeFunction: handleCashFlowChange
                }
            ]
        },
        {
            title: "Investment management",
            questions: [
                {
                    text: "I am satisfied with my understanding of the different types of investment products available.",
                    fieldName: "understandInvestmentProducts",
                    value: investmentManagement.understandInvestmentProducts,
                    changeFunction: handleInvestmentProductsChange
                },
                {
                    text: "I am satisfied with my ability to analyse, evaluate and select different investments.",
                    fieldName: "canAnalyseInvestments",
                    value: investmentManagement.canAnalyseInvestments,
                    changeFunction: handleInvestmentProductsChange
                },
                {
                    text: "I am satisfied with the performance of my investment portfolio.",
                    fieldName: "portfolioPerformance",
                    value: investmentManagement.portfolioPerformance,
                    changeFunction: handleInvestmentProductsChange
                },
                {
                    text: "I am satisfied with the level of risk I’m taking on my investments.",
                    fieldName: "riskLevel",
                    value: investmentManagement.riskLevel,
                    changeFunction: handleInvestmentProductsChange
                }
            ]
        },
        {
            title: "Debt management",
            questions: [
                {
                    text: "I am satisfied with the level of debts I have outstanding.",
                    fieldName: "debt",
                    value: debtManagement.debt,
                    changeFunction: handleDebtManagmentChange
                },
                {
                    text: "I am satisfied with the length of time it will take me to pay off all my loans.",
                    fieldName: "loanPaymentTime",
                    value: debtManagement.loanPaymentTime,
                    changeFunction: handleDebtManagmentChange
                },
                {
                    text: "I am satisfied with the level of protection on my earning capacity over my working life.",
                    fieldName: "interestRateRiseManagement",
                    value: debtManagement.interestRateRiseManagement,
                    changeFunction: handleDebtManagmentChange
                },
                {
                    text: "I am satisfied with my efforts to manage debts responsibly and effectively",
                    fieldName: "canPayDebts",
                    value: debtManagement.canPayDebts,
                    changeFunction: handleDebtManagmentChange
                },
            ]

        },
        {
            title: "Risk management",
            questions: [
                {
                    text: "I am satisfied with my understanding of the financial impact of death, disability or trauma on myself and my family.",
                    fieldName: "understanding",
                    value: riskManagement.understanding,
                    changeFunction: handleRiskManagementChange
                },
                {
                    text: "I am satisfied I have contingencies in place in the event of death, disability or trauma.",
                    fieldName: "contingencies",
                    value: riskManagement.contingencies,
                    changeFunction: handleRiskManagementChange
                },
                {
                    text: "I am satisfied with the level of protection on my earning capacity over my working life.",
                    fieldName: "protection",
                    value: riskManagement.protection,
                    changeFunction: handleRiskManagementChange
                },
                {
                    text: "I am satisfied with my understanding on the different types of insurance available to protect myself and my family in the event of death, disability or trauma.",
                    fieldName: "insurance",
                    value: riskManagement.insurance,
                    changeFunction: handleRiskManagementChange
                },
            ]
        },
        {
            title: "Retirement",
            questions: [

                {
                    text: "I am satisfied that I will have enough saved to achieve a comfortable retirement.",
                    fieldName: "savings",
                    value: retirement.savings,
                    changeFunction: handleRetirementChange
                },
                {
                    text: "I am satisfied with my understanding of the different stages of retirement.",
                    fieldName: "understanding",
                    value: retirement.understanding,
                    changeFunction: handleRetirementChange
                },
                {
                    text: "I am satisfied my retirement savings will last for as long as my lifetime, if not longer.",
                    fieldName: "longevity",
                    value: retirement.longevity,
                    changeFunction: handleRetirementChange
                },
                {
                    text: "I am satisfied my super arrangements are optimised and invested appropriately for my needs.",
                    fieldName: "super",
                    value: retirement.super,
                    changeFunction: handleRetirementChange
                },
            ]
        },
        {
            title: "Estate",
            questions: [

                {
                    text: "I am satisfied the right person has been nominated to look after my financial affairs, make decisions affecting my medical treatment and lifestyle in the event I lose my mental capacity (while I’m still alive).",
                    fieldName: "lifeNomination",
                    value: estate.lifeNomination,
                    changeFunction: handleEstateChange
                },
                {
                    text: "I am satisfied with the arrangements I’ve put in place to ensure my assets are passed onto the appropriate beneficiary and protected from potential creditors.",
                    fieldName: "inheritance",
                    value: estate.inheritance,
                    changeFunction: handleEstateChange
                },
                {
                    text: "I am satisfied the right person has been nominated to carry out my wishes after I pass away.",
                    fieldName: "deathNomination",
                    value: estate.deathNomination,
                    changeFunction: handleEstateChange
                },
                {
                    text: "I am satisfied any taxes resulting from the transfer of assets to my beneficiaries are minimised.",
                    fieldName: "taxMinimised",
                    value: estate.taxMinimised,
                    changeFunction: handleEstateChange
                }
            ]
        }
    ];

    return (
        <>
            <div className="survey-subheading">The statements below will help you think about and assess how satisfied you are with your financial well-being. </div>
            On a scale of 1 to 5 with 1 being the lowest and 5 being the highest, indicate your level of satisfaction with each aspect of your financial position.
            <form onSubmit={handleSubmit} className="financial-satisfaction">
                <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={6} />
                    <Grid item sx={{ display: { xs: 'none', md: 'block' } }}  xs={user?.partner?.firstName ? 3 : 6} className="name"><h3>{user?.firstName ?? ''}</h3></Grid>
                    {user?.partner?.firstName && <Grid sx={{ display: { xs: 'none', md: 'block' } }} item xs={3} className="name"><h3>{user?.partner?.firstName ?? ''}</h3></Grid>}
                    {fsCategories.map(({title, questions}) => 
                        <>
                            <Grid item xs={12} key={title}>
                                <h2>{title}</h2>
                            </Grid>
                            {questions.map(({ text, fieldName, value, changeFunction }, index) =>
                            <FinancialSatisfactionItem questionnaireText={text} fieldName={fieldName} value={value} changeFunction={changeFunction} userFirstName={user?.firstName}  partnerFirstName={user?.partner?.firstName} key={index} />)}
                        </>
                    )}
                    <Grid item xs={12}>
                        <LoadingButton type="submit" variant="contained" color="cta" sx={{ marginTop: '2rem', width: '100%'  }}>Submit</LoadingButton>
                    </Grid>

                </Grid>
            </form>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <br/><br/>
            {displayNext &&
                <div className="submit-success">
                    Thank you for completing the Financial Satisfaction Survey. Your responses are a snapshot of how you feel about your financial wellness at this point in time. The next step is to help us understand your employment and occupation.<br/><br/>
                    <LoadingButton type="button" sx={{ marginTop: '2rem', width: '100%'  }} variant="contained" color="cta" loading={isLoading} onClick={() => gotoNextStep({ cashFlow, investmentManagement, debtManagement, riskManagement, retirement, estate })}>Next</LoadingButton>
                    {isSubmitError && <div className="error-message">{submitError}</div>}
                </div>
            }
        </>
    )
}
