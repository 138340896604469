import { HomeProps } from "@interfaces";
import { Stack, Button, TextField, MenuItem } from "@mui/material";
import React, { ChangeEvent } from "react";

export function Home({home, updateHome, addHome, deleteHome, displayDelete, displayAdd}: HomeProps) {
    const ownerOptions = ["Client", "Partner", "Joint"];
    return <Stack spacing={1}>
        <hr/>
        {displayDelete && <Button type="button" variant="outlined" onClick={e => deleteHome()}>Delete</Button>}
        <TextField required variant="outlined" label="Address" onChange={(event: ChangeEvent<HTMLInputElement>) => updateHome({address: event.target.value})} value={home?.address ?? ''} name="address" fullWidth/>
        <TextField select required value={home.owner} sx={{minWidth: "Owner".length * 20}} label="Owner" name="type" onChange={(event) => updateHome({owner:event.target.value})} variant="outlined">
            {ownerOptions.map((option, index) => 
                <MenuItem value={option.toLowerCase()} key={index}>{option}</MenuItem>
            )}
        </TextField>
        <TextField required variant="outlined" label="Value" onChange={(event: ChangeEvent<HTMLInputElement>) => updateHome({value: Number(event.target.value)})} value={home?.value ?? ''} name="value" fullWidth/>

        {displayAdd && <Button type="button" variant="outlined" onClick={addHome} >Add another home</Button>}

    </Stack>
}