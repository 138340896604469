import { Stepper, MobileStepper, Container, Step, StepLabel, Paper } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from "react";
import { UserValues } from "../interfaces/user";
import { stepsData } from "./steps";
import { useQuery } from '@tanstack/react-query'

import "./SurveyContainer.scss";

import { useMutation } from "@tanstack/react-query";
import { StepData } from "../interfaces/survey";
import { useAxios } from "../util/useAxios";

import inactivityTimer from '../util/inactivityTimer';
import { useNavigate } from "react-router-dom";

export default function SurveyContainer() {
    let [currentStep, setCurrentStep] = useState(0);
    const axios = useAxios();
    const largeScreen = useMediaQuery('(min-width:900px)');
    const navigate = useNavigate();
    inactivityTimer((navigateTo) => navigate(navigateTo, {replace: true}));

    const {data: userResponse, status} = useQuery(['user'], () => axios.get<{user: UserValues}>('user'));

    useEffect(() => {
        if(status === 'success') {
            if(userResponse?.data?.user) {
                setCurrentStep(userResponse?.data.user.step as number);
            }
        }
    }, [status, userResponse?.data.user])

    const dataMutation = useMutation(({body, apiRoute}: {body: StepData, apiRoute: string}) => axios.post(apiRoute, body), {
        onSuccess: () => {
            stepMutation.mutate({step: (currentStep ?? 0) + 1});
        }
    });
    const stepMutation = useMutation(({step}: {step: number}) => axios.post('set-step', step), {
        onSuccess: () => {
            setCurrentStep((currentStep ?? 0) + 1);
        }
    });

    const submitError = (dataMutation.error as {message: string})?.message;

    const {
        isLoading, 
        isError:isSubmitError, 
        mutate
    } = dataMutation;

    const steps = stepsData.map((step, index) => {

        const gotoNextStep = (body?: StepData) => {
            if(body) {

                mutate({body, apiRoute: step.route});


            } else {
                throw new Error("Request body not sent");
            }
        };
        return {
            ...step,
            component: step.component({
                gotoNextStep,
                isSubmitError,
                isLoading,
                submitError,
                user: userResponse?.data.user as UserValues
            })
        }
    });

    return (
        <Container>
            <div className="logo tribel">
                <div className="tribel-text">
                    tribel
                </div>
                <div className="advisory-text">
                    advisory
                </div>
            </div>
            <Paper elevation={3}>
                <div className="heading">
                    {
                        largeScreen
                        ?
                            <Stepper activeStep={currentStep} alternativeLabel>
                                {steps.map(({title}) => (
                                    <Step key={title}>
                                        <StepLabel>{title}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        :
                            <MobileStepper 
                                position="static"
                                steps={10} 
                                variant="dots" 
                                activeStep={currentStep} 
                                nextButton={null} 
                                backButton={null}
                                classes={{
                                    dots: 
                                    'stepperDots',
                                    dot: 
                                    'stepperDot'
                                  }}
                            />
     
                    }
                </div>
                {
                    status === 'loading' 
                    ? 
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={status === 'loading'}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop> 
                    : 
                        steps[currentStep].component
                }
            </Paper>
        </Container>
    )
}
