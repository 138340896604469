import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import appConfig from '../cognitoConfig';
import "./Forgot.scss";
import { TextField, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import validator from 'validator';
import usePageTitle from "../util/usePageTitle";

// TODO: Make this a util function
const userPool = new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
});

export default function Forgot() {
    
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [awaitingVerifyCode, setAwaitingVerifyCode] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    usePageTitle('Forgot Password - Financial Health Check');

    const submitResetEmailAddress = useCallback(() => {
        if (usernameError === "" && username.length > 0) { 
            const userData = {
                Username: username,
                Pool: userPool
            }
            setLoading(true);
            const cognitoUser = new CognitoUser(userData);

            cognitoUser.forgotPassword({ 
                inputVerificationCode() {
                    setAwaitingVerifyCode(true);
                    setLoading(false);
                },
                onSuccess(): void {
                     // do nothing.
                },
                onFailure(): void {
                     // do nothing.
                }
            })
        } 
    },[username, usernameError]);

    const submitResetCode = useCallback(() => {
        if (newPass === '') { 
            setPasswordError("Please enter a new password.");
            return;
        }
        if (resetCode === '') { 
            setCodeError("Please enter the reset code.");
            return;
        }
        setLoading(true);
        const resetUserData = {
            Username: username,
            Pool: userPool
        };

        const cognitoUser = new CognitoUser(resetUserData);

        cognitoUser.confirmPassword(resetCode, newPass, {
            onSuccess() { 
                navigate('/') 
            },
            onFailure(err) { 
                setStatusMessage(err.message); 
                setLoading(false); 
            } 
        });
    },[setStatusMessage,navigate,newPass,resetCode,username ]);

    const handleKeyPressed = useCallback((e: React.KeyboardEvent) => {
        if(e.code === "Enter") {
            !awaitingVerifyCode 
            ? submitResetEmailAddress()
            : submitResetCode()
        }
    },[awaitingVerifyCode, submitResetCode, submitResetEmailAddress]);

    const handlePasswordChange = (passwordInput: string) => {
        setNewPass(passwordInput);
        setStatusMessage('');
        if(passwordInput.length < 6) {
            setPasswordError('Password needs to be 6 or more characters');
        } else {
            setPasswordError('');
        }
    }

    const handleCodeChange = (codeInput: string) => {
        setResetCode(codeInput);
        setStatusMessage('');
        if(codeInput.length < 6) {
            setCodeError('Please enter a valid code');
        } else {
            setCodeError('');
        }
    }

    const handleUsernameChange = (usernameInput: string) => {
        setUsername(usernameInput);
        setStatusMessage('');
        if(!validator.isEmail(usernameInput)) {
            setUsernameError('Please enter a valid email address');
        } else {
            setUsernameError('');
        }
    }

    const userNameChange = useCallback((e) => handleUsernameChange(e.target.value),[]);
    const resetCodeChange = useCallback((e) => handleCodeChange(e.target.value),[]);
    const newPassChange = useCallback((e) => handlePasswordChange(e.target.value),[]);
    const cancelForgotPassword = useCallback(() => navigate('/'),[navigate]);
    const keyPressed = useCallback((e) => handleKeyPressed(e),[handleKeyPressed]);

    return (
        <div className="forgot-container">
            <div className="logo tribel">
                <div className="tribel-text">
                    tribel
                </div>
                <div className="advisory-text">
                    advisory
                </div>
            </div>
            <div className="welcome-body" onKeyUp={keyPressed} role="form" aria-hidden>
                <div className="heading">
                    Forgot Password
                </div>
                {
                    !awaitingVerifyCode 
                    ?
                        <>
                            <div className="message">
                                Enter your email address to reset your password.
                            </div>
                            <TextField error={usernameError !== ''} fullWidth required variant="outlined" value={username} label="Email" onChange={userNameChange} name="username" helperText={usernameError}/>
                            <LoadingButton loading={loading} size="large" color="cta" variant="contained" onClick={submitResetEmailAddress}>SUBMIT</LoadingButton>     
                        </>   
                    :
                        <>
                            <div className="message">
                                A reset code has been sent to your email address. Please enter it below.
                            </div>
                            <TextField error={codeError !== ''} fullWidth required variant="outlined" value={resetCode} label="Code" onChange={resetCodeChange} name="code" helperText={codeError}/>
                            <TextField error={passwordError !== ''} fullWidth required type="password" variant="outlined" value={newPass} label="Password" onChange={newPassChange} name="password" helperText={passwordError}/> 
                            <LoadingButton 
                                loading={loading} 
                                size="large" 
                                color="cta" 
                                variant="contained" 
                                onClick={submitResetCode}>
                                    RESET
                            </LoadingButton> 
                        </>   
                }
                {(statusMessage !== '') && <div>{statusMessage}</div>}
                <Button variant="outlined" size="large" color="cta" onClick={cancelForgotPassword} sx={{height: "40px"}}>  
                    BACK
                </Button>
            </div>
        </div>
    )   
}