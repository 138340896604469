import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { FormEvent, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { SurveyProps } from "@interfaces";
import Child from "./Child";

export default function ChildrenComponent({gotoNextStep, isLoading, submitError, isSubmitError}:SurveyProps) {

    const [children, setChildren] = useState({
        hasChildren: false,
        children: [
            {
                name: "",
                gender: "female",
                dob: new Date()
            }
        ]
    });

    const handleSetChildren = (value: boolean) => {
        if(value !== null) {
            if(value) {
                setChildren({...children, hasChildren: value});
            } else {
                setChildren({...children, hasChildren: value});
            }
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        children.children.forEach(child => {
            if(child.gender === '') {
                // TODO: Set a default gender?
            }
        })

        gotoNextStep(children.hasChildren ? children.children : []);
    };

    const addChild = () => {
        handleUpdateChildData(
            {
                gender: '',
                name: '',
                dob: new Date()
            },
            children.children.length
        );
    }
    
    //TODO: Accordion structures for child, close accordion element when adding a new child

    const handleUpdateChildData = (value: {gender: string} | {name: string} | {dob: Date}, index: number ) => {
        const newChildren = children.children;

        if(!newChildren[index]) {
            const blankChild = { 
                name: '',
                gender: 'female',
                dob: new Date()
            };
            const newChild = {...blankChild, ...value};
            newChildren.push(newChild);
        } else {
            newChildren[index] = {...newChildren[index], ...value};
        }
        setChildren({...children, children: newChildren});
    }

    const deleteChild = (index:number) => {
        const newChildren = children.children;
        newChildren.splice(index, 1);
        setChildren({hasChildren: !!newChildren.length, children: newChildren});
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={5} mt={5} direction="column">
                Do you have any children?
                <ToggleButtonGroup
                    color="primary"
                    value={children.hasChildren}
                    exclusive
                    sx={{ marginTop: "3rem" }}
                    onChange={(event, value) => handleSetChildren(value)} 
                    >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                {children.hasChildren && 
                    <Stack spacing={5} mt={5} direction="column">
                        Some details about your child{ children.children.length === 1 ? '' : 'ren' }:
                        {children.children.map((child, index) => 
                            <Child child={child} updateChild={(childData: { gender: string; } | { name: string; } | { dob: Date; }) => handleUpdateChildData(childData, index)} addChild={addChild} key={index} deleteChild={() => deleteChild(index)}/>
                        )}
                    </Stack>

                }
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }} >Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
    )
}
