import { Stack, TextField, MenuItem} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { ChangeEvent, FormEvent, /*useContext,*/ useState } from "react";
//import { AuthContext } from "../../../contexts";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Address, Partner, UserValues, SurveyProps } from "@interfaces";
import { getFields } from "./fields";

export default function User({gotoNextStep, isLoading, submitError, isSubmitError}:SurveyProps) {

    const [address, setAddress] = useState<Address>({
        streetNumber: '',
        streetName: '',
        suburb: '',
        postcode: '',
        state: '',
        country: ''
    })

    const [user, setUser] = useState<UserValues>({
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        dob: new Date(),
        maritalStatus: ''
    });

    const [partner, setPartner] = useState<Partner>({
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        dob: new Date()
    });

    const [partnerAddress, setPartnerAddress] = useState<Address>({
        streetNumber: '',
        streetName: '',
        suburb: '',
        postcode: '',
        state: '',
        country: ''
    })
/*
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        address: {
           streetNumber: '',
           streetName: '',
           suburb: '',
           postcode: '',
           state: '',
           country: ''
        },
        email: '',
        gender: '',
        dob: '',
        maritalStatus: '',
        partner: {
          firstName: '',
          lastName: '',
          address: {
             streetNumber: '',
             streetName: '',
             suburb: '',
             postcode: '',
             state: '',
             country: ''
          },
          email: '',
          gender: '',
          dob: ''
       }
    });
*/
    const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUser({...user, [event.target.name]: event.target.value})
    }

    const handlePartnerInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPartner({...partner, [event.target.name]: event.target.value})
    }
    const handleSetPartnerAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setPartnerAddress({...partnerAddress, [event.target.name]: event.target.value})
    }

    const handleSetAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress({...address, [event.target.name]: event.target.value})
    }

    const handlePartnerDatePickerChange = (event: Date | null) => {
        if(event !== null) {
            setPartner({...partner, dob: event})
        }
    }

    const handleDatePickerChange = (event: Date | null) => {
        if(event !== null) {
            setUser({...user, dob: event})
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        user.address = address;
        partner.address = partnerAddress;

        if(partner.firstName) {
            user.partner = partner;
        }

       gotoNextStep(user)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="survey-subheading">
                Welcome, we just need to know you a little better before we get started...
            </div>
            <Stack spacing={5} mt={5} direction="column">
                <Stack spacing={2} mt={2} direction="column">
                    {getFields({handleTextInputChange, handleSetAddress, user, address}).map(field => 
                        !field.useAlternateComponent ?
                                <TextField 
                                    select={Boolean(field.children?.length)} 
                                    required 
                                    variant="outlined" 
                                    label={field.label} 
                                    onChange={field.onChange} 
                                    key={field.name}
                                    value={field.value} 
                                    name={field.name}>
                                        {field.children ? field.children.map(child => <MenuItem value={child.value} key={child.key}>{child.content}</MenuItem>) : ''}
                                    </TextField>
                            : <LocalizationProvider dateAdapter={AdapterDateFns} key={field.name}>
                                <DatePicker
                                    label="Date of birth"
                                    views={['day', 'month', 'year']}
                                    value={user.dob}
                                    onChange={handleDatePickerChange}
                                    renderInput={(params: Record<string, unknown>) => <TextField required name="dob" {...params} />}
                                />
                            </LocalizationProvider>
                    )}
                </Stack>
                {user.maritalStatus && user.maritalStatus !== 'single' && 
                    <Stack className="partner-details" spacing={2} mt={2} direction="column">
                        {getFields({handleTextInputChange: handlePartnerInputChange, handleSetAddress: handleSetPartnerAddress, user: partner, address: partnerAddress}).map(field => 
                            !field.useAlternateComponent ?
                                field.name !== 'maritalStatus' ? 
                                    <TextField 
                                        select={Boolean(field.children?.length)} 
                                        required 
                                        variant="outlined" 
                                        label={`Partner's ${field.label}`} 
                                        onChange={field.onChange} 
                                        value={field.value} 
                                        key={field.name}
                                        name={field.name}>
                                            {field.children ? field.children.map(child => <MenuItem value={child.value} key={child.key}>{child.content}</MenuItem>) : ''}
                                        </TextField> : ''
                                : <LocalizationProvider dateAdapter={AdapterDateFns} key={field.name}>
                                    <DatePicker
                                        label="Date of birth"
                                        views={['day', 'month', 'year']}
                                        value={user.dob}
                                        onChange={handlePartnerDatePickerChange}
                                        renderInput={(params: Record<string, unknown>) => <TextField required name="dob" {...params} />}
                                    />
                                </LocalizationProvider>
                        )}
                    </Stack>
                }
                <LoadingButton loading={isLoading} type="submit" variant="contained" color="cta" sx={{ marginTop: '2rem', width: '100%'  }} >Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
    )
}
