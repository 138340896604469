import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://a2d0adee2e7547969d84f40c48ac28d8@o4504087847174144.ingest.sentry.io/4504087848419328",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(    
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
