import React, { ChangeEvent } from "react";
import { Stack, Button, TextField, MenuItem } from "@mui/material";
import { InsuranceProps } from "@interfaces";

export function Insurance({updateInsurance, addInsurance, deleteInsurance, displayDelete, displayAdd, insurance}: InsuranceProps) {

    const insuranceTypes = [
        {
            value: "life",
            label: "Life insurance"
        },
        {
            value: "tpd",
            label: "Total and permanent disability (TPD) insurance"
        },
        {
            value: "income",
            label: "Income protection insurance"
        },
        {
            value: "trauma",
            label: "Trauma or critical illness insurance"
        }
    ];
    const beneficiaryOptions = ["Client", "Partner"];
    const waitingPeriods = [
        {
            value: 14,
            label: "14 days"
        },
        {
            value: 30,
            label: "30 days"
        },
        {
            value: 90,
            label: "90 days"
        },
        {
            value: 180,
            label: "180 days"
        },
        {
            value: 365,
            label: "1 year"
        },
        {
            value: 730,
            label: "2 years"
        },
    ];
    const benefitPeriods = [
        {
            value: "0.5",
            label: "6 months"
        },
        {
            value: "1",
            label: "1 year"
        },
        {
            value: "2",
            label: "2 years"
        },
        {
            value: "5",
            label: "5 years"
        },
        {
            value: "to65",
            label: "To age 65"
        },
        {
            value: "other",
            label: "Other"
        }
    ]
    return <Stack spacing={1} mt={5} direction="column">
        <hr/>
        {displayDelete && <Button type="button" variant="outlined" onClick={e => deleteInsurance()}>Delete</Button>}
        <TextField select required value={insurance.type} label="Type" name="type" onChange={(event) => updateInsurance({type: event.target.value})} variant="outlined">
            {insuranceTypes.map((type, index) => 
                <MenuItem value={type.value} key={index}>{type.label}</MenuItem>
            )}
        </TextField>
        <TextField select required value={insurance.beneficiary} label="Life insured" name="beneficiary" onChange={(event) => updateInsurance({beneficiary: event.target.value})} variant="outlined">
            {beneficiaryOptions.map((beneficiary, index) => 
                <MenuItem value={beneficiary.toLowerCase()} key={index}>{beneficiary}</MenuItem>
            )}
        </TextField>
        {
            (insurance.type === 'life' || insurance.type === 'tpd' || insurance.type === 'trauma') && 
            <TextField required variant="outlined" label="Amount covered" onChange={(event: ChangeEvent<HTMLInputElement>) => updateInsurance({amount: Number(event.target.value)})} value={insurance.amount ?? ''} name="amount" fullWidth/>
        }
        {
            insurance.type === 'income' && 
            <>
                <TextField required variant="outlined" label="Monthly benefit" onChange={(event: ChangeEvent<HTMLInputElement>) => updateInsurance({amount: Number(event.target.value)})} value={insurance.amount ?? ''} name="amount" fullWidth/>
                <TextField select required value={insurance.waitingPeriod === 0 ? null : insurance.waitingPeriod} label="Waiting period" name="waitingPeriod" onChange={(event) => updateInsurance({waitingPeriod: Number(event.target.value)})} variant="outlined">
                    {waitingPeriods.map((period, index) => 
                        <MenuItem value={period.value} key={index}>{period.label}</MenuItem>
                    )}
                </TextField>
                <TextField select required value={insurance.benefitPeriod} label="Benefit Period" name="benefitPeriod" onChange={(event) => updateInsurance({benefitPeriod: event.target.value})} variant="outlined">
                    {benefitPeriods.map((period, index) => 
                        <MenuItem value={period.value} key={index}>{period.label}</MenuItem>
                    )}
                </TextField>
            </>
        }

        {displayAdd && <Button type="button" variant="outlined" onClick={addInsurance} >Add another insurance</Button>}

    </Stack>
}