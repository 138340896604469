import { Stack, TextField, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent, FormEvent, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { SurveyProps, Employment } from "@interfaces";
import { industries } from "./industries";
import { occupations } from "./occupations";

export default function EmploymentSteps({ gotoNextStep, isLoading, submitError, isSubmitError, user }: SurveyProps) {

    const [employment, setEmployment] = useState<Employment>({
        status: {
            client: '',
            partner: '',
        },
        occupation: {
            client: '',
            partner: '',
        },
        industry: {
            client: '',
            partner: '',
        },
        employer: {
            client: '',
            partner: '',
        }
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        gotoNextStep(employment);
    };

    const handleChange = ((event: React.ChangeEvent<HTMLInputElement>, isPartner: boolean) => {
        if (isPartner) {
            setEmployment({ ...employment, [event.target.name]: { ...employment[event.target.name as keyof typeof employment], partner: event.target.value } })
        } else {
            setEmployment({ ...employment, [event.target.name]: { ...employment[event.target.name as keyof typeof employment], client: event.target.value } })

        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={5} direction="column"> 
                <Stack spacing={2} mt={2} direction="column">           
                    <div className="survey-subheading">
                        {user?.firstName}, please enter your employment details:
                    </div>
                    Employment Status
                    <RadioGroup
                        name="status"
                        id="clientStatus"
                        value={employment.status.client}
                        onChange={(event) => handleChange(event, false)}
                    >
                        <FormControlLabel value="fulltime" control={<Radio required />} label="Full-time employed" />
                        <FormControlLabel value="parttime" control={<Radio required />} label="Part-time employed" />
                        <FormControlLabel value="retired" control={<Radio required />} label="Retired" />
                        <FormControlLabel value="selfemployed" control={<Radio required />} label="Self-employed" />
                        <FormControlLabel value="home" control={<Radio required />} label="Home maker" />
                        <FormControlLabel value="unemployed" control={<Radio required />} label="Unemployed" />
                    </RadioGroup>

                    <TextField name="employer" fullWidth required variant="outlined" onChange={e => handleChange(e as ChangeEvent<HTMLInputElement>, false)} value={employment.employer.client} label="Employer" />
                    <TextField select required value={employment.occupation.client} label="Occupation" name="occupation" onChange={(event) => handleChange(event as ChangeEvent<HTMLInputElement>, false)} variant="outlined">
                        {occupations.map((occupation, index) =>
                            <MenuItem value={occupation.trim().toLowerCase().replaceAll(' ', '-')} key={index}>{occupation}</MenuItem>
                        )}
                    </TextField>
                    <TextField select required value={employment.industry.client} label="Industry" name="industry" onChange={(event) => handleChange(event as ChangeEvent<HTMLInputElement>, false)} variant="outlined">
                        {industries.map((industry, index) =>
                            <MenuItem value={industry.trim().toLowerCase().replaceAll(' ', '-')} key={index}>{industry}</MenuItem>
                        )}
                    </TextField>
                </Stack>
                {user?.partner &&
                    <Stack spacing={2} mt={5} direction="column">
                        <div className="survey-subheading">
                            {user?.partner?.firstName}, please enter your employment details:
                        </div>  
                        Employment Status
                        <RadioGroup
                            name="status"
                            id="partnerStatus"
                            value={employment.status.partner}
                            onChange={(event) => handleChange(event, true)}
                        >
                            <FormControlLabel value="fulltime" control={<Radio required />} label="Full-time employed" />
                            <FormControlLabel value="parttime" control={<Radio required />} label="Part-time employed" />
                            <FormControlLabel value="retired" control={<Radio required />} label="Retired" />
                            <FormControlLabel value="selfemployed" control={<Radio required />} label="Self-employed" />
                            <FormControlLabel value="home" control={<Radio required />} label="Home maker" />
                            <FormControlLabel value="unemployed" control={<Radio required />} label="Unemployed" />
                        </RadioGroup>

                        <TextField name="employer" fullWidth required variant="outlined" onChange={e => handleChange(e as ChangeEvent<HTMLInputElement>, true)} value={employment.employer.partner} label="Employer" />
                        <TextField select required value={employment.occupation.partner} label="Occupation" name="occupation" onChange={(event) => handleChange(event as ChangeEvent<HTMLInputElement>, true)} variant="outlined">
                            {occupations.map((occupation, index) =>
                                <MenuItem value={occupation.trim().toLowerCase().replaceAll(' ', '-')} key={index}>{occupation}</MenuItem>
                            )}
                        </TextField>
                        <TextField select required value={employment.industry.partner} label="Industry" name="industry" onChange={(event) => handleChange(event as ChangeEvent<HTMLInputElement>, true)} variant="outlined">
                            {industries.map((industry, index) =>
                                <MenuItem value={industry.trim().toLowerCase().replaceAll(' ', '-')} key={index}>{industry}</MenuItem>
                            )}
                        </TextField>
                        </Stack>
                }
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }} >Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
    )
}
