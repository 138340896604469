import { TextField, MenuItem, Grid } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent, useState } from "react";
import { SurveyProps } from "@interfaces";

export default function ChildrenComponent({gotoNextStep, isLoading, submitError, isSubmitError, user}:SurveyProps) {

    const [shares, setShares] = useState({
        client: '0',
        partner: '0'
    });
    const [property, setProperty] = useState({
        client: '0',
        partner: '0'
    });
    const [bonds, setBonds] = useState({
        client: '0',
        partner: '0'
    });
    const [alternatives, setAlternatives] = useState({
        client: '0',
        partner: '0'
    });


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        gotoNextStep({shares, property, bonds, alternatives });
    //     fetch('https://64b84wk2fj.execute-api.ap-southeast-2.amazonaws.com/master/knowledge', {
    //     method: 'POST',
    //     headers: {
    //         'Authorization': auth.user?.getSignInUserSession()?.getIdToken().getJwtToken()
    //     },
    //     body: JSON.stringify({shares, property, bonds, alternatives}),
    //   }).then(async res => {
    //         await fetch('https://64b84wk2fj.execute-api.ap-southeast-2.amazonaws.com/master/generate-report', {
    //             headers: {
    //                 'Authorization': auth.user?.getSignInUserSession()?.getIdToken().getJwtToken()
    //             },
    //         });
    //         console.warn("Report generated");
    //     });
    
    };

    const options = ["Low", "Medium", "High"];

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>

                <Grid item xs={12}>
                    <div className="survey-subheading">
                        How would you rate your knowledge & experience on the following asset classes (high, medium or low):
                    </div>
                </Grid>
                {user?.partner && 
                    <Grid container sx={{ marginTop: '2rem' }} >
                        <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.firstName ?? ''}</h3></Grid>
                        <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.partner?.firstName ?? ''}</h3></Grid>
                    </Grid>
                }
                <Grid item xs={12} sm={user?.partner ? 6 : 12}>
                    <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.firstName}</h3></Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                        <TextField select required value={shares.client} label="Shares" name="shares" onChange={(event) => setShares({...shares, client: event.target.value})} variant="outlined" fullWidth>
                            {
                                options.map((option, index) => 
                                    <MenuItem value={index} key={index}>{option}</MenuItem>
                                )
                            }
                        </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                        <TextField select required value={property.client} label="Property" name="property" onChange={(event) => setProperty({...property, client: event.target.value})} variant="outlined" fullWidth>
                            {
                                options.map((option, index) => 
                                    <MenuItem value={index} key={index}>{option}</MenuItem>
                                )
                            }
                        </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                        <TextField select required value={bonds.client} label="Bonds" name="bonds" onChange={(event) => setBonds({...bonds, client: event.target.value})} variant="outlined" fullWidth>
                            {
                                options.map((option, index) => 
                                    <MenuItem value={index} key={index}>{option}</MenuItem>
                                )
                            }
                        </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                        <TextField select required value={alternatives.client} label="Alternatives" name="alternatives" onChange={(event) => setAlternatives({...alternatives, client: event.target.value})} variant="outlined" fullWidth>
                            {
                                options.map((option, index) => 
                                    <MenuItem value={index} key={index}>{option}</MenuItem>
                                )
                            }
                        </TextField>
                        </Grid>
                </Grid>
                {user?.partner && 
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.partner?.firstName}</h3></Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                            <TextField select required value={shares.partner} label="Shares" name="shares" onChange={(event) => setShares({...shares, partner: event.target.value})} variant="outlined" fullWidth>
                                {
                                    options.map((option, index) => 
                                        <MenuItem value={index} key={index}>{option}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                            <TextField select required value={property.partner} label="Property" name="property" onChange={(event) => setProperty({...property, partner: event.target.value})} variant="outlined" fullWidth>
                                {
                                    options.map((option, index) => 
                                        <MenuItem value={index} key={index}>{option}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                            <TextField select required value={bonds.partner} label="Bonds" name="bonds" onChange={(event) => setBonds({...bonds, partner: event.target.value})} variant="outlined" fullWidth>
                                {
                                    options.map((option, index) => 
                                        <MenuItem value={index} key={index}>{option}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item sx={{padding: { xs: '0.5rem 0.5rem' }}}>
                            <TextField select required value={alternatives.partner} label="Alternatives" name="alternatives" onChange={(event) => setAlternatives({...alternatives, partner: event.target.value})} variant="outlined" fullWidth>
                                {
                                    options.map((option, index) => 
                                        <MenuItem value={index} key={index}>{option}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                }            
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }}>Send report</LoadingButton>
            </Grid>
        </form>
    )
}
