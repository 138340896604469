import { Button, TextField, Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChildProps } from "../../../interfaces/children";
import FormLabel from '@mui/material/FormLabel';
// TODO: Move this into children
export default function Child({child, updateChild, addChild, deleteChild}: ChildProps) {

    const [displayAddButton, setDisplayAddButton] = useState(true);

    const handleAddChildClicked = () => {
        setDisplayAddButton(false);
        addChild();
    }
// TODO: Make these not grid, can just be stack as they don't need to be 2D
    return (
        <>
        <hr />
        <Grid container >
            <Grid item xs={12} sm={10}>
                <TextField required variant="outlined" label="Name" onChange={(event: ChangeEvent<HTMLInputElement>) => updateChild({name: event.target.value})} value={child?.name ?? ''} name="name" fullWidth/>
            </Grid>
            <Grid item xs={12} sm={2} >
                <Button sx={{ height: '3.4rem', width: '100%'}} type="button" variant="outlined" onClick={deleteChild}>Delete</Button>
            </Grid>
        
            <Grid item xs={12} sm={4} md={2} >
                <FormLabel>Gender</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8} md={10} >
                <RadioGroup
                    row
                    name="gender"
                    value={child?.gender ?? "female"}
                    onChange={(event, value) => updateChild({gender: value})}
                >
                    <FormControlLabel value="male" labelPlacement="bottom" control={<Radio required />} label="Male" />
                    <FormControlLabel value="female" labelPlacement="bottom" control={<Radio required />} label="Female" />
                    <FormControlLabel value="other" labelPlacement="bottom" control={<Radio required />} label="Other" />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={8} md={10} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date of birth"
                        views={['day', 'month', 'year']}
                        value={child?.dob}
                        onChange={(event: Date) => updateChild({dob: event as Date})}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params: Record<string, unknown>) => <TextField required name="dob" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
        {displayAddButton && <Button type="button" variant="outlined" onClick={handleAddChildClicked} >Add another child</Button>}
    </>
    )
}