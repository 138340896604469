import React, { useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthContext } from "../contexts";
import { LocationProps } from "../interfaces/user";
import validator from 'validator';
import "./Login.scss";

type loginProps = {
    isActive: boolean,
    toggleActive: VoidFunction,
}

export default function Login({isActive, toggleActive}: loginProps) {
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userError, setUserError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation() as unknown as LocationProps;
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const from = location.state?.from?.pathname || "/survey";

    const changeActive = useCallback(() => {
        toggleActive();
        setStatusMessage('');
        setUserError('');
        setPasswordError('');
        setUsername('');
        setPassword('');
    },[setStatusMessage, setUserError, setUsername, setPassword, toggleActive]);

    const submitLogin = useCallback(() => {
        if (username === '') { 
            setUserError("Please enter your email address.");
            return;
        }
        if (password === '') { 
            setPasswordError("Please enter a password.");
            return;
        }
        setLoading(true);
        auth.signin({username, password}, () => {
            navigate(from, {replace: true});
        }, () => {
            setStatusMessage("Unsuccessful login, please try again");
            setLoading(false);
        });
    },[auth, setLoading, username, password, from, navigate]);

    const handleEnter = (e: React.KeyboardEvent) => {
        if(e.code === "Enter") {
            submitLogin();
        }
    }

    const handleEmailChange = (email: string) => {
        setUsername(email);
        setStatusMessage('');
        if (validator.isEmail(email)) {
            setUserError('');
        } else { 
            setUserError('Invalid email format');
        }
    }

    const handlePasswordChange = (passwordChangeValue: string) => {
        setPassword(passwordChangeValue);
        setStatusMessage('');
        if (passwordChangeValue.length === 0) {
            setPasswordError('Please enter your password');
        }
        else {
            setPasswordError('');
        }
    }

    const userNameChange = useCallback((e) => handleEmailChange(e.target.value),[]);
    const userPassChange = useCallback((e) => handlePasswordChange(e.target.value),[]);

    return (
        <div className={`login-container ${!isActive ? 'inactive' : ''}`}>
            <div className="logo tribel">
                <div className="tribel-text">
                    tribel
                </div>
                <div className="advisory-text">
                    advisory
                </div>
            </div>
            <div className="welcome-body" onKeyUp={handleEnter} role="form" aria-hidden>
                {
                !isActive ? 
                <>
                    <div className="heading">
                        Welcome Back!
                    </div>
                    <div className="message">
                        Log in with your account to connect to your personal info
                    </div>
                    <Button variant="outlined" color="cta" onClick={changeActive}>SIGN IN</Button>
                </>
                :
                <>
                    <div className="heading">
                        Sign In
                    </div>
                
                    <TextField error={userError !== ''} fullWidth required variant="outlined" onChange={userNameChange} value={username} label="Email" helperText={userError} />
                    <TextField error={passwordError !== ''}  required fullWidth type="password" label="Password" variant="outlined" onChange={userPassChange} value={password} helperText={passwordError}/>
                    <LoadingButton 
                        loading={loading} 
                        size="large" 
                        color="cta" 
                        variant="contained" 
                        onClick={submitLogin}>
                            SIGN IN
                    </LoadingButton>          
                    {(statusMessage !== '') && <div className="error">{statusMessage}</div>}
                    <div>
                        <Link to="/forgot-password">Forgot password</Link>
                    </div>
                </>
                }
            </div>
        </div>
    )
}