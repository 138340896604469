import React, { useContext } from "react";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import AuthenticationContainer from "./authentication/AuthenticationContainer";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { authProvider } from "./auth";
import { UserParams, VerificationParams } from "./interfaces/user";
import { AuthContext } from "./contexts";
import './App.scss';
import { CognitoUser } from "amazon-cognito-identity-js";
import Forgot from "./authentication/Forgot";
import { createTheme, ThemeProvider } from "@mui/material";
import SurveyContainer from "./survey/SurveyContainer";

declare module '@mui/material/styles' {
  interface Palette {
    cta: {
      main: Palette['primary']
    },
  }
  interface PaletteOptions {
    cta: PaletteOptions['primary'];
  }

}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cta: true
  }
}

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsColorOverrides {
    cta: true
  }
}

export default function App() {

  const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat', 'Montserrat-light', 'system-ui', 'sans-serif'].join(',')
    },
    palette: {
      cta: {
        main: '#F43B47',
        contrastText: '#FFF',
        dark: '#EA6B66'
      },
    }
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient} >
          <div className="app-container tribel-theme">
          <Routes>

            <Route element={<Outlet />}>
                <Route path="/" element={<AuthenticationContainer />} />
                <Route path="/login" element={<AuthenticationContainer />} />
                <Route path="/forgot-password" element={<Forgot />} />

                <Route
                  path="/survey"
                  element={
                    <RequireAuth>
                        <SurveyContainer />
                    </RequireAuth>            
                  }
                />
            </Route>
          </Routes>
          </div>
        </QueryClientProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}


function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useContext(AuthContext);
  const location = useLocation();


  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function AuthProvider({children}: {children: React.ReactNode}) {
  const [user, setUser] = React.useState<CognitoUser>();

  const signin = (signInUser: UserParams, successCallback: (user: CognitoUser) => void, failedCallback: (error: Error) => void) => {
    return authProvider.signin(signInUser, (newUser) => {
      setUser(newUser);
      successCallback(newUser);
    }, failedCallback);
  };

  const signout = (callback: VoidFunction) => {
    return authProvider.signout(user as CognitoUser, () => {
      setUser(undefined);
      callback();
    });
  };

  const register = (registerUser: UserParams,successCallback: (user: CognitoUser) => void, failedCallback: (error: Error) => void) => {
    return authProvider.register(registerUser, (newUser) => {
      setUser(newUser);
      successCallback(newUser);
    }, failedCallback);
  };

  const verifyEmail = (data: VerificationParams, successCb: (message: string) => void, failedCb: (message: Error) => void) => {
    return authProvider.verifyEmail(data, successCb, failedCb);
  }


  const value = { user, signin, signout, register, verifyEmail };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}