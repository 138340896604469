import { SuperProps } from "@interfaces";
import { Grid, Button, TextField, MenuItem, Tooltip, IconButton, Stack } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import HelpIcon from '@mui/icons-material/Help';

export function Super({updateSuper, addSuper, deleteSuper, displayDelete, displayAdd, superObj}: SuperProps) {
    const ownerOptions = ["Client", "Partner"];
    const [contributionLevel, setContributionLevel] = useState<string>();
    const contributionOptions = [
        {
            label: "No",
            value: "no"
        },
        {
            label: "Yes, the standard super guarantee only",
            value: "standard"
        },
        {
            label: "Yes, but other amount, please specify amount",
            value: "other"
        }
    ];

    const handleContributionLevelChange = (newContributionLevel: string | null) => {
        updateSuper("contributionLevel.isStandardAmount", newContributionLevel === 'standard');
        if(newContributionLevel === 'no') {
            updateSuper('contributionLevel.customAmount', 0);
        }
        setContributionLevel(newContributionLevel as string);
    }
    return ( 
        <Stack spacing={1}>
            <hr/>
            {displayDelete && <Button type="button" variant="outlined" onClick={e => deleteSuper()}>Delete</Button>}

            <TextField required variant="outlined" label="Fund name" onChange={(event: ChangeEvent<HTMLInputElement>) => updateSuper('name', event.target.value)} value={superObj?.name ?? ''} name="name" fullWidth/>
            <TextField select required value={superObj.owner} label="Owner" name="type" onChange={(event) => updateSuper('owner', event.target.value)} variant="outlined" fullWidth>
                {ownerOptions.map((option, index) => 
                    <MenuItem value={option.toLowerCase()} key={index}>{option}</MenuItem>
                )}
            </TextField>
            <TextField required variant="outlined" label="Account balance" onChange={(event: ChangeEvent<HTMLInputElement>) => updateSuper('balance', Number(event.target.value))} value={superObj?.balance ?? ''} name="balance" fullWidth/>
            <TextField select required value={contributionLevel} label="Contribution Level" name="type" onChange={(event) => handleContributionLevelChange(event.target.value)} variant="outlined" fullWidth>
                {contributionOptions.map((option, index) => 
                    <MenuItem value={option.value} key={index}>{option.label}</MenuItem>
                )}
            </TextField>

        {
            contributionLevel === 'other' && 
            <Grid container>
                <Grid item xs={8}>
                    <TextField required variant="outlined" label="Contribution Amount" onChange={(event: ChangeEvent<HTMLInputElement>) => updateSuper('contributionLevel.customAmount', Number(event.target.value))} value={superObj?.contributionLevel.customAmount ?? ''} name="contributionAmount" fullWidth/>
                </Grid>
                <Grid item xs={4} sx={{ position: 'relative' }}>
                    <TextField select required value={superObj.contributionLevel.isPercentage} label="Type" name="type" onChange={(event) => updateSuper('contributionLevel.isPercentage', event.target.value === 'true')} variant="outlined" fullWidth>
                        <MenuItem value="false">$</MenuItem>
                        <MenuItem value="true">%</MenuItem>
                    </TextField>
                    <Tooltip title={
                        <>
                            If you&apos;re unsure how much super your employer is paying you, check your:
                            <ul>
                                <li>Payslip</li>
                                <li>myGov account</li>
                                <li>super account - online or by calling your fund</li>
                            </ul>
                            Employers only have to transfer super into your super account once a quarter (every three months). Some choose to pay more often. Ask your employer how often they pay yours.
                            <br/><br/>
                            If you&apos;re not getting the right amount, talk to your employer. If your employer isn&apos;t paying your super, report them to the ATO.
                        </>
                    }>
                        <IconButton sx={{ position: 'absolute', top: '0', right: '0'}}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        }
        {displayAdd && <Button type="button" variant="outlined" onClick={addSuper} >Add another super</Button>}
    </Stack>
    )
}