import { Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent,  useState } from "react";
import {  SurveyProps } from "@interfaces";

export default function Estate({gotoNextStep, isLoading, submitError, isSubmitError, user}:SurveyProps) {

    const [hasWill, setHasWill] = useState({
        client: false,
        partner: false
    });
    const [hasBeenReviewed, setHasBeenReviewed] = useState({
        client: false,
        partner: false
    });
    const [hasAssetProtection, setHasAssetProtection] = useState({
        client: false,
        partner: false
    });
    const [hasContingencies, setHasContingencies] = useState({
        client: false,
        partner: false
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        gotoNextStep({hasWill, hasBeenReviewed, hasAssetProtection, hasContingencies});
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <div className="survey-subheading">
                    Do you have a valid Will in place?
                </div>
            </Grid>
            <Grid container spacing={2}>   
                {user?.partner && 
                    <Grid container sx={{ marginTop: '2rem' }} >
                        <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.firstName ?? ''}</h3></Grid>
                        <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.partner?.firstName ?? ''}</h3></Grid>
                    </Grid>
                }
                <Grid item xs={12} md={user?.partner ? 6 : 12} sx={{ marginTop: {xs: '2rem', md: '0' } }}>
                    
                    <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.firstName}</h3></Grid>
                    <Grid item xs={12} >
                        <RadioGroup
                            name="hasWill.client"
                            value={hasWill.client}
                            onChange={(event, value) => setHasWill({...hasWill, client: value === 'true'})}
                        >
                            <FormControlLabel value={false} control={<Radio required />} label="No"/>
                            <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                        </RadioGroup>
                    </Grid>

                    {hasWill.client && 
                    <>
                        <Grid item xs={12} sx={{mt: 2}}>
                            Has your will been reviewed in the last 5 years?
                            <RadioGroup
                                name="hasBeenReviewed.client"
                                value={hasBeenReviewed.client}
                                onChange={(event, value) => setHasBeenReviewed({...hasBeenReviewed, client: value === 'true'})}
                            >
                                <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 2}}>
                            Has your Will and estate plans considered the following:
                        </Grid>
                        <Grid item xs={12} sx={{pl: 2, mt: 1}}>
                            Asset protection for beneficiaries, tax implications and potential family challenges?
                            <RadioGroup
                                name="hasAssetProtection.client"
                                value={hasAssetProtection.client}
                                onChange={(event, value) => setHasAssetProtection({...hasAssetProtection, client: value === 'true'})}
                            >
                                <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                            </RadioGroup>
                        </Grid>                    
                        <Grid item xs={12} sx={{pl: 2}}>
                            Have contingencies in place in the event you lose mental capacity?
                            <RadioGroup
                                name="hasContingencies.client"
                                value={hasContingencies.client}
                                onChange={(event, value) => setHasContingencies({...hasContingencies, client: value === 'true'})}
                            >
                                <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                            </RadioGroup>
                        </Grid>
                    </>
                    }
                </Grid>
                {user?.partner &&
                    <Grid item xs={12} md={6} sx={{ marginTop: {xs: '2rem', md: '0' } }}>
                        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.partner?.firstName}</h3></Grid>
                        
                        <Grid item xs={12}>
                            <RadioGroup
                                name="hasWill.partner"
                                value={hasWill.partner}
                                onChange={(event, value) => setHasWill({...hasWill, partner: value === 'true'})}
                            >
                                <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                            </RadioGroup>
                        </Grid>

                        
                        {hasWill.partner &&
                        <>   
                            <Grid item xs={12} sx={{mt: 2}}>
                                Has your will been reviewed in the last 5 years?
                                <RadioGroup
                                    name="hasBeenReviewed.partner"
                                    value={hasBeenReviewed.partner}
                                    onChange={(event, value) => setHasBeenReviewed({...hasBeenReviewed, partner: value === 'true'})}
                                >
                                    <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                    <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2}}>
                                Has your Will and estate plans considered the following:
                            </Grid>
                            <Grid item xs={12} sx={{pl: 2, mt: 1}}>
                                Asset protection for beneficiaries, tax implications and potential family challenges?
                                <RadioGroup
                                    name="hasAssetProtection.partner"
                                    value={hasAssetProtection.partner}
                                    onChange={(event, value) => setHasAssetProtection({...hasAssetProtection, partner: value === 'true'})}
                                >
                                    <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                    <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} sx={{pl: 2}}>
                                Have contingencies in place in the event you lose mental capacity?
                                <RadioGroup
                                    name="hasContingencies.partner"
                                    value={hasContingencies.partner}
                                    onChange={(event, value) => setHasContingencies({...hasContingencies, partner: value === 'true'})}
                                >
                                    <FormControlLabel value={false} control={<Radio required />} label="No"/>
                                    <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                                </RadioGroup>
                            </Grid>
                            
                        </>
                        }
                    </Grid>
                }
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }}>Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}          
            </Grid>
        </form>
    )
}
