import {  TextField, MenuItem,Grid, RadioGroup, FormControlLabel, Radio, Checkbox } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent, useEffect, useState } from "react";
import { useAxios } from "util/useAxios";
import { SurveyProps, PERIOD } from "@interfaces";
import { useQuery } from "@tanstack/react-query";

const INTEREST_RATE = 0.08;

export default function IncomeExpenses({gotoNextStep, isLoading, submitError, isSubmitError, user}:SurveyProps) {

    const [showClientHecs, setShowClientHecs] = useState(false);
    const [showPartnerHecs, setShowPartnerHecs] = useState(false);
    const [budgetCalculationMethod, setBudgetCalculationMethod] = useState("");
    const [annualisedLivingExpenses, setAnnualisedLivingExpenses] = useState(0);
    const axios = useAxios();

    // TODO: Stop this running on every page
    const {data: totalLiability} = useQuery(['totalLiability'], () => axios('total-liability'));
    const {data: totalTaxDeductions} = useQuery(['totalLiability'], () => axios('total-deductions'));

    // TODO: Type this properly
    const [incomeExpenses, setIncomeExpenses] = useState<any>({
    takeHomePay: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    bonuses: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    investments: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    centrelink: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    familyBenefit: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    childSupport: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    other: {
        client: {
            amount: 0,
            period: PERIOD.Monthly
        },
        partner: {
            amount: 0,
            period: PERIOD.Monthly
        },
    },
    taxResidency: {
        client: true,
        partner: true
    },
    hecsDebt: {
        client: 0,
        partner: 0
    },
    budget: {
        hasBudget: true,
        spending: {
            amount: 0,
            period: PERIOD.Monthly,
        },
        savings: {
            amount: 0,
            period: PERIOD.Monthly,
        }
    },
    });

    useEffect(() => {
        if(budgetCalculationMethod === 'savings' && incomeExpenses.budget.savings.amount !== 0){ 
            const calculateTaxPaid = () => {
                let taxPaid = 0;
                const totalExertionIncome = 
                    incomeExpenses.takeHomePay.client.amount * incomeExpenses.takeHomePay.client.period + 
                    incomeExpenses.bonuses.client.amount * incomeExpenses.bonuses.client.period + 
                    incomeExpenses.takeHomePay.partner.amount * incomeExpenses.takeHomePay.partner.period + 
                    incomeExpenses.bonuses.partner.amount * incomeExpenses.bonuses.partner.period;
    
                const totalInvestmentIncome = 
                    incomeExpenses.investments.client.amount * incomeExpenses.investments.client.period + 
                    incomeExpenses.investments.partner.amount * incomeExpenses.investments.partner.period;
                const grossTaxableIncome = totalExertionIncome + totalInvestmentIncome;
                const taxExemptIncome = INTEREST_RATE * totalTaxDeductions?.data * 0.5;
                const taxableIncome = grossTaxableIncome - taxExemptIncome;
                // TODO: Use tax brackets object from BE
                if(incomeExpenses.taxResidency.client){
                    switch(true) {
                        case taxableIncome <= 18200:
                            taxPaid = 0 * taxableIncome;
                            break;
                        case taxableIncome <= 45000:
                            taxPaid = 0.19 * taxableIncome;
                            break;
                        case taxableIncome <= 120000:
                            taxPaid = 0.325 * taxableIncome + 5092;
                            break;
                        case taxableIncome <= 180000:
                            taxPaid = 0.37 * taxableIncome + 29467;
                            break;
                        default:
                            taxPaid = 0.45 * taxableIncome + 51667;
                    }
                } else {
                    switch(true) {
                        case taxableIncome <= 120000:
                            taxPaid = 0 * taxableIncome;
                            break;
                        case taxableIncome <= 180000:
                            taxPaid = 0.37 * taxableIncome + 39000;
                            break;
                        default:
                            taxPaid = 0.45 * taxableIncome + 61200;
                    }
                }
                return taxPaid;
            }
            const totalIncome =
                Number(incomeExpenses.takeHomePay.client.amount) * incomeExpenses.takeHomePay.client.period + 
                Number(incomeExpenses.takeHomePay.partner.amount) * incomeExpenses.takeHomePay.partner.period + 
                Number(incomeExpenses.bonuses.client.amount) * incomeExpenses.bonuses.client.period + 
                Number(incomeExpenses.bonuses.partner.amount) * incomeExpenses.bonuses.partner.period + 
                Number(incomeExpenses.investments.client.amount) * incomeExpenses.investments.client.period + 
                Number(incomeExpenses.investments.partner.amount) * incomeExpenses.investments.partner.period + 
                Number(incomeExpenses.centrelink.client.amount) * incomeExpenses.centrelink.client.period + 
                Number(incomeExpenses.centrelink.partner.amount) * incomeExpenses.centrelink.partner.period + 
                Number(incomeExpenses.familyBenefit.client.amount) * incomeExpenses.familyBenefit.client.period + 
                Number(incomeExpenses.familyBenefit.partner.amount) * incomeExpenses.familyBenefit.partner.period + 
                Number(incomeExpenses.childSupport.client.amount) * incomeExpenses.childSupport.client.period + 
                Number(incomeExpenses.childSupport.client.amount) * incomeExpenses.childSupport.client.period + 
                Number(incomeExpenses.other.client.amount) * incomeExpenses.other.client.period +
                Number(incomeExpenses.other.partner.amount) * incomeExpenses.other.partner.period;
            
            setAnnualisedLivingExpenses(
                totalIncome - 
                (incomeExpenses.budget.savings.amount * incomeExpenses.budget.savings.period) - 
                totalLiability?.data - 
                calculateTaxPaid()
            )
        } else if(budgetCalculationMethod === 'spending' && incomeExpenses.budget.spending.amount !== 0) {
            setAnnualisedLivingExpenses(incomeExpenses.budget.spending.amount * incomeExpenses.budget.spending.period)
        } else {
            setAnnualisedLivingExpenses(0);
        }
    }, [budgetCalculationMethod, incomeExpenses, totalLiability, totalTaxDeductions?.data])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        gotoNextStep(incomeExpenses);
    };

    const handleChecked = ((event: React.ChangeEvent<HTMLInputElement>, value:boolean) => {
        const incomeExpensesNew = incomeExpenses;

        const keyPath: any[] = event.target.name.split('.');
        incomeExpensesNew[keyPath[0]][keyPath[1]] = value;
        setIncomeExpenses({...incomeExpenses, ...incomeExpensesNew});

    })

    const handleChange = ((event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const incomeExpensesNew = incomeExpenses;
        let newValue: string | number = event.target.value;

        if(event.target.name.includes('.')) {
            const keyPath:  any[] = event.target.name.split('.');
            if(keyPath.length === 2) {
                if(keyPath[1] === 'amount' || keyPath[0] === 'hecsDebt') {
                    newValue = Math.round((Number(event.target.value) + Number.EPSILON) * 100) / 100;
                    if(newValue < 0) {
                        newValue = 0;
                    }
                }
                incomeExpensesNew[keyPath[0]][keyPath[1]] = newValue;
            } else if(keyPath.length === 3) {
                if(keyPath[2] === 'amount') {
                    newValue = Math.round((Number(event.target.value) + Number.EPSILON) * 100) / 100;
                    if(newValue < 0) {
                        newValue = 0;
                    }
                }
                incomeExpensesNew[keyPath[0]][keyPath[1]][keyPath[2]] = newValue;
            }
            setIncomeExpenses({...incomeExpenses, ...incomeExpensesNew});

        }
    });

    const convertZeroIntoBlankString = (amount: number) => {
        return amount === 0 ? "" : amount
    }

    // TODO: Map instead of copy/pasting
    return (
        <>
            <div className="survey-subheading">
                Please enter your income details below
            </div>
        
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    
                    {user?.partner && 
                        <Grid container sx={{ marginTop: '2rem' }} >
                            <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.firstName ?? ''}</h3></Grid>
                            <Grid item xs={6} justifyContent="center" sx={{ display: { xs: 'none', md: 'block' } }} ><h3>{user?.partner?.firstName ?? ''}</h3></Grid>
                        </Grid>
                    }


                    <Grid container>
                        <Grid item xs={12} md={user?.partner ? 6 : 12} sx={{ marginTop: {xs: '2rem', md: '0' } }}>
                            <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.firstName}</h3></Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                    <TextField fullWidth required variant="outlined" label="Take home pay" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.takeHomePay.client.amount)} name="takeHomePay.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.takeHomePay.client.period)} label="Period" name="takeHomePay.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                       
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                    <TextField fullWidth required variant="outlined" label="Bonuses & overtime" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.bonuses.client.amount)} name="bonuses.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                   
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.bonuses.client.period)} label="Period" name="bonuses.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                       
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} > 
                                    <TextField fullWidth required variant="outlined" label="Income from savings & investments" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.investments.client.amount)} name="investments.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                  
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.investments.client.period)} label="Period" name="investments.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                        
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} > 
                                    <TextField fullWidth required variant="outlined" label="Centrelink benefits" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.centrelink.client.amount)} name="centrelink.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                                                
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.centrelink.client.period)} label="Period" name="centrelink.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid> 
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                        
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                    <TextField fullWidth required variant="outlined" label="Family benefit payments" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.familyBenefit.client.amount)} name="familyBenefit.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                      
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.familyBenefit.client.period)} label="Period" name="familyBenefit.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid> 
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                     
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                    <TextField fullWidth required variant="outlined" label="Child support received" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.childSupport.client.amount)} name="childSupport.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                                            
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.childSupport.client.period)} label="Period" name="childSupport.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid> 
                            </Grid>
                            <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>                    
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                    <TextField fullWidth required variant="outlined" label="Other" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.other.client.amount)} name="other.client.amount" />
                                </Grid>
                                <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                             
                                    <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.other.client.period)} label="Period" name="other.client.period" onChange={handleChange} variant="outlined">
                                        <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                        <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                        <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                        <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                        <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                        <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                    </TextField>
                                </Grid> 
                            </Grid>
                            <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                <FormControlLabel control={<Checkbox checked={incomeExpenses.taxResidency.client} name="taxResidency.client" onChange={handleChecked} />} label="I am an Australian tax resident" />
                            </Grid> 
                            <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                <FormControlLabel control={<Checkbox checked={showClientHecs} onChange={(e, value) => setShowClientHecs(value)} />} label="I have HECS debt" />
                            </Grid>
                            {showClientHecs && 
                            <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                    <TextField fullWidth required variant="outlined" label="HECS Balance" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.hecsDebt.client)} name="hecsDebt.client"/>
                            </Grid>
                            }
                            <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                <FormControlLabel control={<Checkbox checked={incomeExpenses.budget.hasBudget} name="budget.hasBudget" onChange={handleChecked} />} label="I have a budget" />
                            </Grid>
                            {
                                incomeExpenses.budget.hasBudget && 
                                    <Grid container>
                                        <Grid sx={{padding: '0 0.5rem' }} item xs={12}>
                                            Working out how much you spend on living expenses can be difficult. If you already have a budget then that’s great. If not, you can either provide a rough estimate of how much you spend or how much you save.
                                        </Grid>
                                        <RadioGroup
                                            name="budgetCalculation"
                                            value={budgetCalculationMethod}
                                            onChange={(event, value) => setBudgetCalculationMethod(value)}
                                            >
                                            <FormControlLabel value="spending" control={<Radio required />} label="I would like to use spending to budget" />                    
                                            <FormControlLabel value="savings" control={<Radio required />} label="I would like to use savings to budget" />                    
                                        </RadioGroup>
                                        {
                                            budgetCalculationMethod === 'spending' && 
                                                <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12}>
                                                        How much do you spend on living expenses, excluding any regular loan repayments?
                                                    </Grid>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                                        
                                                        <TextField fullWidth required variant="outlined" label="Amount" helperText="" onChange={handleChange} value={incomeExpenses.budget.spending.amount === 0 ? '' : incomeExpenses.budget.spending.amount} name="budget.spending.amount" />                              
                                                    </Grid>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}> 
                                                        <TextField fullWidth select required value={incomeExpenses.budget.spending.period} label="Period" name="budget.spending.period" onChange={handleChange} variant="outlined">
                                                            <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                            <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                            <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                            <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                        }           
                                        {
                                            budgetCalculationMethod === 'savings' &&
                                                <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12}>
                                                        How much is saved after paying all your bills?
                                                    </Grid>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                                        <TextField fullWidth required variant="outlined" label="Amount" helperText="" onChange={handleChange} value={incomeExpenses.budget.savings.amount === 0 ? '' : incomeExpenses.budget.savings.amount} name="budget.savings.amount" />
                                                    </Grid>
                                                    <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}> 
                                                        <TextField fullWidth select required value={incomeExpenses.budget.savings.period} label="Period" name="budget.savings.period" onChange={handleChange} variant="outlined" >
                                                            <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                            <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                            <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                            <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                        }

                                        {annualisedLivingExpenses > 0 && 
                                            <Grid item xs={12}>
                                                <br/>
                                                You currently spend approximately ${annualisedLivingExpenses} per annum on living expenses. Does that sound right?
                                            </Grid>
                                        }
                                        <br/>
                                    </Grid>
                            }
                        </Grid> 





                        {
                            user?.partner &&
                                <Grid item xs={12} md={6} sx={{ marginTop: {xs: '2rem', md: '0' } }}>
                                    <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}><h3>{user?.partner?.firstName}</h3></Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}} >
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                            <TextField fullWidth required variant="outlined" label="Take home pay" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.takeHomePay.partner.amount)} name="takeHomePay.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.takeHomePay.partner.period)} label="Period" name="takeHomePay.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} > 
                                            <TextField fullWidth required variant="outlined" label="Bonuses & overtime" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.bonuses.partner.amount)} name="bonuses.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.bonuses.client.period)} label="Period" name="bonuses.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} > 
                                            <TextField fullWidth required variant="outlined" label="Income from savings & investments" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.investments.partner.amount)} name="investments.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.investments.client.period)} label="Period" name="investments.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid> 
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} > 
                                            <TextField fullWidth required variant="outlined" label="Centrelink benefits" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.centrelink.partner.amount)} name="centrelink.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                                    
                                        <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.centrelink.client.period)} label="Period" name="centrelink.partner.period" onChange={handleChange} variant="outlined">
                                            <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                            <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                            <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                            <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                            <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                            <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                        </TextField>
                                        </Grid> 
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                            <TextField fullWidth required variant="outlined" label="Family benefit payments<" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.familyBenefit.partner.amount)} name="familyBenefit.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                      
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.familyBenefit.client.period)} label="Period" name="familyBenefit.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid> 
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                            <TextField fullWidth required variant="outlined" label="Child support received" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.childSupport.partner.amount)} name="childSupport.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                                                 
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.childSupport.client.period)} label="Period" name="childSupport.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid> 
                                    </Grid>
                                    <Grid container sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={8} >
                                            <TextField fullWidth required variant="outlined" label="Other" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.other.partner.amount)} name="other.partner.amount" />
                                        </Grid>
                                        <Grid sx={{padding: { xs: '0.5rem 0.5rem' }}} item xs={12} sm={4}>                        
                                            <TextField fullWidth select required value={convertZeroIntoBlankString(incomeExpenses.other.client.period)} label="Period" name="other.partner.period" onChange={handleChange} variant="outlined">
                                                <MenuItem value={PERIOD.Hourly}>Hourly</MenuItem>
                                                <MenuItem value={PERIOD.Daily}>Daily</MenuItem>
                                                <MenuItem value={PERIOD.Weekly}>Weekly</MenuItem>
                                                <MenuItem value={PERIOD.Fortnightly}>Fortnightly</MenuItem>
                                                <MenuItem value={PERIOD.Monthly}>Monthly</MenuItem>
                                                <MenuItem value={PERIOD.Yearly}>Yearly</MenuItem>
                                            </TextField>
                                        </Grid> 
                                    </Grid> 
                                    <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                        <FormControlLabel control={<Checkbox checked={incomeExpenses.taxResidency.partner} name="taxResidency.partner" onChange={handleChecked} />} label="I am an Australian tax resident" />
                                    </Grid>
                                    <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                        <FormControlLabel control={<Checkbox checked={showPartnerHecs} onChange={(e, value) => setShowPartnerHecs(value)} />} label="I have HECS debt" />
                                    </Grid>
                                    { 
                                        showPartnerHecs && 
                                        <Grid item sx={{padding: { xs: '0 0.5rem' }}} >
                                                <TextField fullWidth required variant="outlined" label="HECS Balance" onChange={handleChange} value={convertZeroIntoBlankString(incomeExpenses.hecsDebt.partner)} name="hecsDebt.partner"/>
                                        </Grid>
                                    }
                                </Grid>
                        }
                    </Grid>
                    <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading} sx={{ marginTop: '2rem', width: '100%'  }} >Next</LoadingButton>
                    {isSubmitError && <div className="error-message">{submitError}</div>}  
                </Grid>
            </form>
        </>
    )
}
