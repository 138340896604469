import React from "react";
import { Rating, Grid } from "@mui/material";
import { FsItemProps } from "@interfaces";

export default function FinancialSatisfactionItem({questionnaireText, fieldName, value, changeFunction, userFirstName, partnerFirstName }: FsItemProps) {
    return (
     <>
    <Grid item xs={12} md={6}>{questionnaireText}</Grid>
        <Grid item xs={6} className="name" sx={{ display: { xs: partnerFirstName?'block':'none', md: 'none' } }}>{userFirstName}</Grid>
        <Grid item xs={6} md={partnerFirstName? 3 : 6 }> 
            <Rating
                name={fieldName}
                value={value.client}
                onChange={(event, newValue) => changeFunction(event, newValue as number, false)}
            />
    </Grid>
    {
        partnerFirstName && 
        <>
            <Grid item xs={6} className="name" sx={{ display: { xs: 'block', md: 'none' } }}>{partnerFirstName}</Grid>  
            <Grid item xs={6} md={3}>
                <Rating
                    name={fieldName}
                    value={value.partner}
                    onChange={(event, newValue) => changeFunction(event, newValue as number, true)}
                />
            </Grid>
        </>
   }
   </>
   )
}