import { Stack, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { SurveyProps, IAssets, HomeValue, SavingsValue, InvestmentValue } from "@interfaces";
import {Home} from "./Home";
import {Super} from "./Super";
import {Investments} from "./Investments";
import {Savings} from "./Savings";

export default function Assets({gotoNextStep, isLoading, submitError, isSubmitError}:SurveyProps) {

    const [ownsHome, setOwnsHome] = useState(false);
    const [hasSuper, setHasSuper] = useState(false);
    const [hasSavings, setHasSavings] = useState(false);
    const [hasInvestments, setHasInvestments] = useState(false);
    const [assets, setAssets] = useState<IAssets>({
        homes: [],
        super: [],
        savings: [],
        investments: []
    })

    const handleUpdateHomeData = useCallback((homeData: HomeValue, index: number) => {
        const newHomes = [...assets.homes];
        newHomes[index] = {...newHomes[index], ...homeData};

        setAssets({...assets, homes: newHomes})
    }, [assets]);

    const setEmptyAsset = useCallback((assetType: keyof IAssets, emptyFieldName: string, flag: boolean, handleFunction: (...args: any) => void) => {
        if(assets[assetType].length === 0 && flag) {
            if(assetType === 'super') {
                handleFunction(emptyFieldName, '', 0);
            } else {

                handleFunction({[emptyFieldName]: ''}, 0);
            }
        } else if(!flag) {
            setAssets({...assets, [assetType]: []});
        }
    }, [assets])


    const handleUpdateSuperData = useCallback((field: string, fieldValue: any, index: number) => {
        const superNew = [...assets.super];

        const keyPath = field.split('.');
        if(keyPath.length === 2) {
            const childElement = keyPath[1] as keyof {isStandardAmount: boolean, customAmount?:number, isPercentage: boolean};
            const contributionLevel = superNew[index].contributionLevel as {isStandardAmount: boolean, customAmount?:number, isPercentage: boolean};
            const newContribution = {...contributionLevel, [childElement]: fieldValue}
            superNew[index] = {...superNew[index], contributionLevel: newContribution};
        } else {
            superNew[index] = {...superNew[index], [field]: fieldValue}
        };
        setAssets({...assets, super: superNew});
    }, [assets])

    const handleUpdateSavingsData = useCallback((savingsData: SavingsValue, index: number) => {
        const newSavings = [...assets.savings];
        newSavings[index] = {...newSavings[index], ...savingsData};

        setAssets({...assets, savings: newSavings})
    }, [assets]);

    const handleUpdateInvestmentData = useCallback((investmentData: InvestmentValue, index: number) => {
        const newInvestments = [...assets.investments];
        newInvestments[index] = {...newInvestments[index], ...investmentData};

        setAssets({...assets, investments: newInvestments})
    }, [assets]);

    useEffect(() => setEmptyAsset('homes', 'address', ownsHome, handleUpdateHomeData), [ownsHome, handleUpdateHomeData, setEmptyAsset]);
    useEffect(() => setEmptyAsset('investments', 'name', hasInvestments, handleUpdateInvestmentData), [hasInvestments, handleUpdateInvestmentData, setEmptyAsset]);
    useEffect(() => setEmptyAsset('super', 'name', hasSuper, handleUpdateSuperData), [hasSuper, handleUpdateSuperData, setEmptyAsset]);
    useEffect(() => setEmptyAsset('savings', 'owner', hasSavings, handleUpdateSavingsData), [hasSavings, handleUpdateSavingsData, setEmptyAsset]);


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        gotoNextStep(assets);
    }

    const deleteHome = (index: number) => {
        const newHomes = [...assets.homes];
        newHomes.splice(index, 1);
        setAssets({...assets, homes: newHomes});
    }
    const deleteSuper = (index: number) => {
        const newSuper = [...assets.super];
        newSuper.splice(index, 1);
        setAssets({...assets, super: newSuper});
    }
    const deleteSavings = (index: number) => {
        const newSavings = [...assets.savings];
        newSavings.splice(index, 1);
        setAssets({...assets, savings: newSavings});
    }
    const deleteInvestment = (index: number) => {
        const newInvestments = [...assets.investments];
        newInvestments.splice(index, 1);
        setAssets({...assets, investments: newInvestments});
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2} mt={5} direction="column">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Home</AccordionSummary>
                    <AccordionDetails>
                        Do you own your own home?
                        <RadioGroup
                            name="ownsHome"
                            value={ownsHome}
                            onChange={(event, value) => setOwnsHome(value === 'true')}
                        >
                            <FormControlLabel value={false} control={<Radio required />} label="No, I'm renting or boarding"/>
                            <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                        </RadioGroup>
                        {ownsHome && 
                        <>
                            {assets.homes.map((home, index) => 
                                <Home home={home} updateHome={homeData => handleUpdateHomeData(homeData, index)} addHome={() => handleUpdateHomeData({address: ""}, assets.homes.length)} key={index} deleteHome={() => deleteHome(index)} displayDelete={assets.homes.length !== 1} displayAdd={index === assets.homes.length - 1}/>
                            )}
                        </>}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Super</AccordionSummary>
                    <AccordionDetails>
                        Do you have any super?
                        <RadioGroup
                            name="hasSuper"
                            value={hasSuper}
                            onChange={(event, value) => setHasSuper(value === 'true')}
                        >
                            <FormControlLabel value={false} control={<Radio required />} label="No"/>
                            <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                        </RadioGroup>
                        {hasSuper && 
                        <>
                            {assets.super.map((superObj, index) => 
                                <Super superObj={superObj} updateSuper={(field, fieldValue) => handleUpdateSuperData(field, fieldValue, index)} addSuper={() => handleUpdateSuperData("name", "", assets.super.length)} key={index} deleteSuper={() => deleteSuper(index)} displayDelete={assets.super.length !== 1} displayAdd={index === assets.super.length - 1} />
                            )}
                        </>
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Savings</AccordionSummary>
                    <AccordionDetails>
                        Do you have any cash or savings accounts?
                        <RadioGroup
                            name="hasSavings"
                            value={hasSavings}
                            onChange={(event, value) => setHasSavings(value === 'true')}
                        >
                            <FormControlLabel value={false} control={<Radio required />} label="No"/>
                            <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                        </RadioGroup>
                        {hasSavings && 
                        <>
                            {assets.savings.map((savings, index) => 
                                <Savings savings={savings} updateSavings={savingsData => handleUpdateSavingsData(savingsData, index)} addSavings={() => handleUpdateSavingsData({balance: 0}, assets.savings.length)} key={index} deleteSavings={() => deleteSavings(index)} displayDelete={assets.savings.length !== 1} displayAdd={index === assets.savings.length - 1} />
                            )}
                        </>
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Investments</AccordionSummary>
                    <AccordionDetails>
                        Do you have any investments?
                        <RadioGroup
                            name="hasInvestments"
                            value={hasInvestments}
                            onChange={(event, value) => setHasInvestments(value === 'true')}
                        >
                            <FormControlLabel value={false} control={<Radio required />} label="No"/>
                            <FormControlLabel value={true} control={<Radio required />} label="Yes"/>
                        </RadioGroup>
                        {hasInvestments && 
                        <>
                            {assets.investments.map((investment, index) => 
                                <Investments investment={investment} updateInvestment={investmentData => handleUpdateInvestmentData(investmentData, index)} addInvestment={() => handleUpdateInvestmentData({value: 0}, assets.savings.length)} key={index} deleteInvestment={() => deleteInvestment(index)} displayDelete={assets.investments.length !== 1} displayAdd={index === assets.investments.length - 1} />
                            )}
                        </>
                        }
                    </AccordionDetails>
                </Accordion>
                <LoadingButton type="submit" variant="contained" color="cta" loading={isLoading}>Next</LoadingButton>
                {isSubmitError && <div className="error-message">{submitError}</div>}
            </Stack>
        </form>
        </>
    )
}

