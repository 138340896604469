import React, { useState, useCallback } from "react";
import { Grid } from '@mui/material';
import Login from "./Login";
import Register from "./Register";
import './AuthenticationContainer.scss';
import usePageTitle from "../util/usePageTitle";

export default function AuthenticationContainer() {

    const [isLoginActive, setLoginActive] = useState(true);
    const [isRegisterActive, setRegisterActive] = useState(false);
    const [backgroundIsRight, setBackgroundIsRight] = useState(true);
    const [pageTitle, setPageTitle] = useState('Login - Financial Health Check');

    usePageTitle(pageTitle);

    const toggleActive = useCallback(() => {
        setBackgroundIsRight(!backgroundIsRight);
        setLoginActive(!isLoginActive);
        setRegisterActive(!isRegisterActive);
        isLoginActive 
        ? setPageTitle('Register - Financial Health Check')
        : setPageTitle('Login - Financial Health Check')
    },[backgroundIsRight, isLoginActive, isRegisterActive]);

    return (
        <div className="auth-container" >
            <div className={`inactive-background ${backgroundIsRight ? 'right' : ''}`} />
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Login isActive={isLoginActive} toggleActive={toggleActive} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}  >
                    <Register isActive={isRegisterActive} toggleActive={toggleActive}/>
                </Grid>
            </Grid>
        </div>
    )
}
