import { Button, Grid, MenuItem, Stack, TextField} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { PERIOD, LiabilityProps } from "@interfaces";

export default function Liability({liability, updateLiability, addLiability, deleteLiability, forceDisplayAddButton}: LiabilityProps) {

    const [displayAddButton, setDisplayAddButton] = useState(true);
    const loans = [
        {
            type: "home",
            label: "Home loan"
        },
        {
            type: "investment",
            label: "Investment loan"
        },
        {
            type: "personal",
            label: "Personal loan"
        },
        {
            type: "credit",
            label: "Credit card"
        },
        {
            type: "other",
            label: "Other"
        }
    ]
    const borrowers = [
        {
            type: "client",
            label: "Client"
        },
        {
            type: "partner",
            label: "Partner"
        },        
        {
            type: "joint",
            label: "Joint"
        },        
    ]
    const repaymentTypes = [
        {
            type: "interest",
            label: "Interest only"
        },
        {
            type: "principalAndInterest",
            label: "Principal & Interest"
        }
    ];
    const ccRepaymentTypes = [
        {
            type: "full",
            label: "Fully paid at end of each month"
        },
        {
            type: "partial",
            label: "Partial payment"
        }
    ];
    const repaymentPeriods = ["Weekly", "Fortnightly", "Monthly", "Yearly" ]

    const handleAddLiabilityClicked = () => {
        setDisplayAddButton(false);
        addLiability();
    }

    return (
        <>
            <hr />
            <Stack spacing={2} mt={2} direction="column"> 
                <Grid item alignSelf={"flex-end"} xs={1} >
                    <Button type="button" variant="outlined" onClick={e => deleteLiability()}>Delete</Button>
                </Grid>
                <TextField select required value={liability.type} sx={{minWidth: "Loan type".length * 20}} label="Loan type" name="type" onChange={(event) => updateLiability("type", event.target.value)} variant="outlined">
                    {loans.map((loan, index) => 
                        <MenuItem value={loan.type} key={index}>{loan.label}</MenuItem>
                    )}
                </TextField>
                {liability.type === "other" && 
                    <TextField required variant="outlined" label="Custom loan type" onChange={(event: ChangeEvent<HTMLInputElement>) => updateLiability("customType", event.target.value)} value={liability?.customType ?? ''} name="customType" fullWidth/>
                }
                <TextField select required value={liability.borrower} sx={{minWidth: "Borrower".length * 20}} label="Borrower" name="borrower" onChange={(event) => updateLiability("borrower", event.target.value)} variant="outlined">
                    {borrowers.map((borrower, index) => 
                        <MenuItem value={borrower.type} key={index}>{borrower.label}</MenuItem>
                    )}
                </TextField>

                <TextField required variant="outlined" label="Current loan balance" onChange={(event: ChangeEvent<HTMLInputElement>) => updateLiability("balance", event.target.value)} value={liability?.balance ?? ''} name="balance" fullWidth/>
                <TextField required variant="outlined" label="Interest rate (per annum)" onChange={(event: ChangeEvent<HTMLInputElement>) => updateLiability("interestRate", event.target.value)} value={liability?.interestRate ?? ''} name="interestRate" fullWidth/>

                <TextField select required value={liability.repaymentType} sx={{minWidth: "Repayment type".length * 20}} label="Repayment type" name="repaymentType" onChange={(event) => updateLiability("repaymentType", event.target.value)} variant="outlined">
                    {liability.type === "credit" ? 
                        ccRepaymentTypes.map((repaymentType, index) => 
                        <MenuItem value={repaymentType.type} key={index}>{repaymentType.label}</MenuItem>)
                    : repaymentTypes.map((repaymentType, index) => 
                        <MenuItem value={repaymentType.type} key={index}>{repaymentType.label}</MenuItem>
                    )}
                </TextField>

                {liability.repaymentType !== "full" && <TextField required variant="outlined" label="Repayment amount" onChange={(event) => updateLiability("repayment.amount", event.target.value)} value={liability.repayment.amount} name="repayment.amount"/>}
                            
                {liability.type !== "credit" && <TextField select required value={liability.repayment.period} sx={{minWidth: "Period".length * 20}} label="Period" name="repayment.period" onChange={(event) => updateLiability("repayment.period", event.target.value)} variant="outlined">
                    {repaymentPeriods.map((period, index) => 
                        <MenuItem value={PERIOD[period as keyof typeof PERIOD]} key={index}>{period}</MenuItem>
                    )}
                </TextField>}
            </Stack>
            {(displayAddButton || forceDisplayAddButton) && <Button type="button" variant="outlined" onClick={handleAddLiabilityClicked} >Add another liability</Button>}
            
        </>
    )
}