import { authProvider } from '../auth';
import appConfig from "../cognitoConfig";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
});

const inactivityTimer = (successCb: (navigateTo: string) => void) => {

    const cognitoUser = userPool.getCurrentUser() as CognitoUser;
    let thisTimer: ReturnType<typeof setTimeout>;
    
    const resetTimer = () => {
        clearTimeout(thisTimer);
        thisTimer = setTimeout(()=>{
            authProvider.signout(cognitoUser,() => { 
                successCb("/login");
            });
        }, 900000);
    }
    document.addEventListener('input', resetTimer);
    document.addEventListener('submit', resetTimer);
    resetTimer();
}
export default inactivityTimer;